const { useManualQuery } = require('graphql-hooks');

const searchMessageBuyerQuery = `query SearchMessageBuyer($orderId: String) {
  searchMessageBuyer(orderId: $orderId) {
    status
    messages {
      id
      from {
        user_id
      }
      to {
        user_id
      }
      status
      text
      message_date {
        read
        received
        available
        notified
        created
        timeLapsed
      }
      message_attachments {
        original_filename
        size
        filename
        type
        convertedSize
      }
    }
  }
}`;

const useSearchMessageBuyerManual = () => {
  const [searchMessageBuyer, { loading, error, data }] = useManualQuery(
    searchMessageBuyerQuery
  );

  const { messages, status } = data
    ? data.searchMessageBuyer
    : { messages: [], status: '' };

  return { searchMessageBuyer, loading, error, messages, status };
};

export { useSearchMessageBuyerManual };
