import React from 'react';

export const ProgressBar = ({ progress }: { progress: number; }) => {
    const progressBarStyle = {
        width: `${progress}%`,
        background: 'linear-gradient(0deg, #9E77ED, #6941C6, #9E77ED)',
        animation: 'color-change 5s linear infinite'
    };

    return (
        <div className="w-full shadow-sm h-8 bg-primary-200 rounded-md">
            <div className="h-full shadow-md bg-gradient-to-r from-sky-500 to-primary-700 transition-all duration-250 rounded-md" style={progressBarStyle}></div>
        </div>
    );
};
