import { useState, useEffect } from 'react';
import { CatalogProductsList } from './CatalogProductsList';
import { ElegibleProductsList } from './ElegibleProductsList';
import { ProductFilterTabs } from './ProductFilterTabs';
import { MlCatalogProductsList } from './MlCatalogProductList/MlCatalogProductsList';
//user permission
import { useSearchPermissionManual } from 'hooks/useSearchPermissions';
import AlertMessage from './utils/AlertMessage';
import { DANGERMSGALERT } from './utils/constants';

//module permission
import { flags } from './utils/flags';

const ProductsPage = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [showManualPublicationButton, setShowManualPublicationButton] =
    useState(false);
  const [showProductPage, setShowProductPage] = useState(false);

  const {
    searchPermission,
    error: errorSearchPermission,
    loading: loadingSearchPermission,
  } = useSearchPermissionManual();

  useEffect(() => {
    const fetchUserPermission = async () => {
      const { data } = await searchPermission();
      // console.log('user permission up', data.searchPermission);
      if (errorSearchPermission) {
        if (errorSearchPermission.httpError) {
          console.log(
            `${errorSearchPermission.httpError.statusText} - para obtener los permisos`
          );
        }
      }

      if (data) {
        const { isAdmin, lastActiveStore } = data.searchPermission;

        if (isAdmin) {
          setShowManualPublicationButton(true);
          setShowProductPage(true);
        } else if (lastActiveStore.permissions.length > 0) {
          lastActiveStore.permissions.includes('manual_publishing') &&
            setShowManualPublicationButton(true);
          lastActiveStore.permissions.includes('catalog_publishing') &&
            setShowProductPage(true);
        }
      }
    };

    fetchUserPermission();
  }, []);

  return (
    <div className='ml-4'>
      <div className='font-sans mt-8'>
       { flags.showModuleHeader && <div className='font-semibold text-3xl mb-12'>Artículos</div>}
        {!showProductPage && !loadingSearchPermission && <div className='w-9/12'><AlertMessage message='No tiene permisos para ver esta p&aacute;gina!' title='Atencion' type={DANGERMSGALERT} /></div>}
        {/* {showProductPage && (
          <ProductFilterTabs onTabSelected={setSelectedTabNumber} />
        )} */}
        {selectedTabNumber === 0 && showProductPage && (
          <MlCatalogProductsList
            query=''
            showManualPublicationButton={showManualPublicationButton}
            selectedTabNumber={selectedTabNumber}
            setSelectedTabNumber={setSelectedTabNumber}
          />
        )}
        {selectedTabNumber === 1 && showProductPage && (
          <ElegibleProductsList query='' setSelectedTabNumber={setSelectedTabNumber} />
        )}
        {selectedTabNumber === 2 && showProductPage && (
          <CatalogProductsList query='' setSelectedTabNumber={setSelectedTabNumber} />
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
