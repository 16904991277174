import { useState } from 'react';
import { RemoveRowButtonComponent } from './RemoveRowButtonComponent';

export const RangeSelector = ({
  min,
  max,
  percentage,
  idx,
  ranges,
  onMinChange,
  onMaxChange,
  onPercentageChange,
  onRemoveRow,
}) => {
  const isLastRecord = idx >= ranges.length - 1;
  const [iconTitle, setIconTitle] = useState('Remover fila');

  const handlerOnMinChange = (value) => {
    onMinChange(value);
  };

  const handlerOnMaxChange = (value) => {
    onMaxChange(value);
  };

  const handlerOnPercentageChange = (value) => {
    onPercentageChange(value);
  };

  return (
    <div className='mb-4 flex w-full'>
      <div className='w-[15%]'>
        <span className='mr-2.5 w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
          Min
        </span>
      </div>
      <div className={`w-[15%] ${idx == 0 && 'text-center mt-2'}`}>
        {idx > 0 ? (
          <input
            type='number'
            name='min'
            id='min'
            onChange={(e) => handlerOnMinChange(e.target.value)}
            className='rounded'
            maxLength='3'
            size='4'
            min={ranges[idx - 1].max}
            max={+ranges[idx].max - 1}
            value={min}
          />
        ) : (
          min
        )}
      </div>
      <div className='w-[15%]'>
        <span className='mr-2.5 w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
          Max
        </span>
      </div>
      <div className={`w-[15%] ${isLastRecord && 'text-center mt-2'}`}>
        {!isLastRecord ? (
          <input
            type='number'
            name='max'
            id='max'
            onChange={(e) => handlerOnMaxChange(e.target.value)}
            className='rounded'
            maxLength='3'
            size='4'
            min={+ranges[idx].min + 1}
            max={ranges[idx + 1].min}
            value={max}
          />
        ) : (
          !isFinite(max) && 'n'
        )}
      </div>
      <div className='w-[15%]'>
        <span className='mr-2.5 w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
          %
        </span>
      </div>
      <div className='w-[15%]'>
        <input
          type='number'
          name='percentage'
          id='percentage'
          onChange={(e) => handlerOnPercentageChange(e.target.value)}
          className='rounded'
          maxLength='3'
          size='4'
          min={0}
          max={100}
          value={percentage}
        />
      </div>
      {idx > 0 && idx < ranges.length - 1 && (
        <span className='w-[10%] h-full mt-2'>
          <RemoveRowButtonComponent
            position={idx}
            onRemoveRow={onRemoveRow}
            iconTitle={iconTitle}
          />
        </span>
      )}
    </div>
  );
};
