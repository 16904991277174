import React from 'react';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddUser, { EditUserPermissions, PERMISSION_OPTIONS } from './AddUser';
import { useDispatch } from 'react-redux';
import { showMessage, useRemoveUserFromStoreMutation } from 'store';
import { IconType, MessageType } from 'components/utils/PromptDialog';
import { HidePanelIcon, ShowPanelIcon } from 'components/utils/Icons';

export interface UserItemList {
  id: string;
  email: string;
  permissions: string[];
}

const UsersPage = ({ userInfoData }: any) => {
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const { usersInStore }: { usersInStore: UserItemList[] } = userInfoData || {};
  const [removeUserFromStore] = useRemoveUserFromStoreMutation();
  const dispatch = useDispatch();

  const permissionsPreProcess = PERMISSION_OPTIONS.map((p) => [
    p.value,
    p.label,
  ]);
  const permissionsProcess: any = [];
  permissionsPreProcess.forEach((p) => {
    permissionsProcess[p[0]] = p[1];
  });

  const handleDeleteUser = (idToRemove: string, email: string) => {
    dispatch(
      showMessage({
        content: `¿Desea eliminar el usuario ${email}?`,
        messageType: MessageType.YES_NO,
        iconType: IconType.QUESTION,
        handleConfirm: async () => {
          try {
            await removeUserFromStore({
              data: {
                id: idToRemove,
              },
            }).unwrap();
          } catch (err) {
            console.log('>> Error on remove user from store: ', err);
          }
        },
      })
    );
  };

  const handleEditUser = (userItemList: UserItemList) => {
    dispatch(
      showMessage({
        content: EditUserPermissions(userItemList),
        messageType: MessageType.NONE,
        iconType: IconType.EDIT,
      })
    );
  };

  return (
    <div className='ml-60'>
      <div className='font-sans mt-8 relative'>
        <div className='absolute right-0 top-0'>
          <button
            onClick={() => {
              setShowAddUser(!showAddUser);
            }}
            className='bg-gray-500 text-white font-semibold text-sm px-4 py-2 rounded-lg hover:bg-gray-700 m-1 cursor-pointer'
          >
            {showAddUser ? <HidePanelIcon /> : <ShowPanelIcon />}
            Agregar nuevo usuario a la tienda
          </button>
        </div>
        <div className='font-semibold text-3xl mb-12'>Usuarios</div>
      </div>

      {showAddUser ? <AddUser /> : ''}

      <div className='m-3'>
        <div className='font-semibold text-xl mt-8 mb-3'>
          Usuarios existentes
        </div>
        {Array.isArray(usersInStore) && usersInStore.length ? (
          <table className='m-0 p-0 w-full'>
            <thead className='text-md text-gray-700 text-left w-full'>
              <tr>
                <th className='py-1 pl-3 bg-green-500'>E-mail</th>
                <th className='py-1 bg-green-500'>Permisos</th>
                <th className='py-1 bg-green-500'>Acciones</th>
              </tr>
            </thead>
            <tbody className='text-sm'>
              {usersInStore.map((userItemList: UserItemList, idx: number) => {
                return (
                  <tr
                    key={`tr-${userItemList.id}`}
                    className={`bg-gray-${idx % 2 === 0 ? '0' : '100'}`}
                  >
                    <td className='py-2 pl-3'>{userItemList.email}</td>
                    <td>
                      {userItemList.permissions
                        .reduce((prev: string, curr: string) => {
                          return prev + permissionsProcess[curr] + ', ';
                        }, '')
                        .slice(0, -2)}
                    </td>
                    <td>
                      <button
                        className='mr-2'
                        onClick={() => {
                          handleEditUser(userItemList);
                        }}
                      >
                        <ModeEditIcon />
                      </button>
                      <button
                        onClick={() => {
                          handleDeleteUser(userItemList.id, userItemList.email);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className='text-md mt-8 mb-3 text-center p-5 bg-gray-100'>
            Aun no se han agregado usuarios a la tienda
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersPage;
