import { useQuery, useManualQuery } from 'graphql-hooks';

const GetAnswerSuggestionsQuery = `
  query GetAnswerSuggestions($query: String!, $sku: String!, $type: String) {
    getAnswerSuggestions(query: $query, sku: $sku, type: $type)
  }
`

export const useGetAnswerSuggestions = (query, sku, type) => {
    const { loading, error, data } = useQuery(GetAnswerSuggestionsQuery, {
        variables: {
            query,
            sku,
            type
        }
    });
    return { loading, error, data }; 
}

export const useGetAnswerSuggestionsManual = () => {
    const [getAnswerSuggestions, { loading, error, data }] = useManualQuery(GetAnswerSuggestionsQuery);
    return { getAnswerSuggestions, loading, error, data }; 
}
