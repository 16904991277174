import { useState } from 'react';
import { useBidOnProductManual } from '../hooks/useBidOnProduct';


export const BidOnProductModal = ({ product, currentMin, currentMax, onBid, onCancel }) => {
    const { bidOnProduct, data, loading, error } = useBidOnProductManual();
    const [minPrice, setMinPrice] = useState(product.currentBid ? product.currentBid.minPrice : product.price);
    const [maxPrice, setMaxPrice] = useState(product.currentBid ? product.currentBid.maxPrice : product.price);


    const handleClickBackground = (e) => {
        typeof onCancel === 'function' && onCancel();
        e.stopPropagation();
    };

    const handleBidOnProduct = async () => {
        if(!product.permalink.includes('/p/')) return; // TODO: show error
        const bid = await bidOnProduct({ variables: { 
            productId: product.id,
            catalogProductId: product.permalink.split('/p/')[1],
            minPrice: parseFloat(minPrice),
            maxPrice: parseFloat(maxPrice),
            currency: product.currency_id,
            active: true
        }});
        // console.log('bid product en modal',bid);
        typeof onBid === 'function' && onBid(bid);
    };

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm" onClick={handleClickBackground}>
            <div className="m-auto relative bg-white rounded-md p-2 w-60 h-40 shadow-md shadow-gray-900" onClick={e => e.stopPropagation()}>
                <div className="text-xl font-bold mb-4">Pujar</div>

                <div className="flex flex-inline m-2">
                    <label className="text-sm font-semibold">Mínimo:</label>
                    <input className="w-1/2 rounded-md" value={minPrice} onChange={e => setMinPrice(e.target.value)} placeholder="mínimo" type="number" />
                </div>
                <div className="flex flex-inline m-2">
                    <label className="text-sm font-semibold">Máximo:</label>
                    <input className="w-1/2 rounded-md" value={maxPrice} onChange={e => setMaxPrice(e.target.value)} placeholder="máximo" type="number" />
                </div>

                <div className="absolute bottom-2 right-2">
                    <button className="bg-gray-200 rounded-md px-2 py-1 mr-2 cursor-pointer" onClick={onCancel}>Cancelar</button>
                    <button className="bg-primary-700 text-white rounded-md px-2 py-1" onClick={handleBidOnProduct}>Pujar</button>
                </div>
            </div>
        </div>
    );
};
