import AddRowIcon from "../iconsComponents/AddRowIcon";

const CostosImpuestos = ({ editSetting }) => {
  return (
    <>
      <div className='w-full flex'>
        <div className='w-[30%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <span className='align-center'>
              <h2></h2>
            </span>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='cuota'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  % cuota del giro
                </label>
                <div className='flex w-full mt-2'>
                  <div className='w-[15%] mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[55%]'>
                    <input
                      type='number'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={8}
                      className='rounded'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='meli'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Tipo de envio (MELI)
                </label>
                <div className='mt-2 w-full'>
                  <select
                    id='meli'
                    name='meli'
                    className='w-full h-[2.7rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  >
                    <option value='cadc'>Mercado envios (CADC)</option>
                    <option value='cadv'>Mercado envios (CADV)</option>
                    <option value='coordVendedor'>
                      Envio a coordinar con el vendedor
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='dias'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Dias de espera del producto (Disponibilidad del producto)
                </label>
                <div className='mt-2'>
                  <input
                    type='text'
                    name='dias'
                    id='dias'
                    className='block w-full h-[2.7rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    placeholder='Ej. 15 dias'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[30%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoAmazon'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  % global de impuesto en los productos sobre precio Amazon
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-[55%]`}>
                    <select
                      id='impuestoAmazon'
                      name='impuestoAmazon'
                      className='block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    >
                      <option value='0'>Precio + costo flete</option>
                      <option value='1'>Precio sin costo flete</option>
                    </select>
                  </div>
                  <div className='w-[15%] ml-2.5 mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[15%]'>
                    <input
                      type='number'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={4}
                      className='rounded'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoAsinAmazon'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  % de impuesto en ASIN Amazon
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-[55%]`}>
                    <select
                      id='impuestoAsinAmazon'
                      name='impuestoAsinAmazon'
                      className='block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    >
                      <option value='0'>Precio + costo flete</option>
                      <option value='1'>Precio sin costo flete</option>
                    </select>
                  </div>
                  <div className='w-[15%] ml-2.5 mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[15%]'>
                    <input
                      type='number'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={4}
                      className='rounded'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoAmazon'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Filtro lista negra
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-full`}>
                    <input
                      type='text'
                      name='listaNegra'
                      id='listaNegra'
                      className='block w-full h-[2.7rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      placeholder='Ej. apple, garmin...'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[30%]'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoCategoria'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  % de impuesto sobre categoria MELI
                </label>
                <div className='w-full flex mt-2 justify-start'>
                  <AddRowIcon title='Agregar pad de categoria'/>
                </div>
                {/* <div className='flex w-full mt-2'>
                  <div className={`w-[55%]`}>
                    <select
                      id='impuestoCategoria'
                      name='impuestoCategoria'
                      className='block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    >
                      <option value='categoria1'>categoria 1...</option>
                    </select>
                  </div>
                  <div className='w-[15%] ml-2.5 mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[15%]'>
                    <input
                      type='number'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={4}
                      className='rounded'
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <button
          className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150`}
          type='submit'
        >
          Guardar
        </button>
      </div>
    </>
  );
};

export default CostosImpuestos;
