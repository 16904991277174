import { useQuery, useManualQuery } from 'graphql-hooks';

const SearchCatalogProductsQuery = `
  query SearchCatalogProducts($query: String!, $offset: Int, $limit: Int) {  
    searchMyCatalogProducts (query: $query, offset: $offset, limit: $limit) {
      offset
      total
      products {
        id
        title
        site_id
        price
        currency_id
        available_quantity
        sold_quantity
        thumbnail,
        permalink,
        pictures {
          url
        }
        currentBid {
          productId,
          userId,
          minPrice
          maxPrice
          currency
          active
          id
        }
      }
    }
  }
`
const useSearchCatalogProducts = (query) => {
    const { loading, error, data } = useQuery(SearchCatalogProductsQuery, {
        variables: { query }
    });
    const { products, offset, total } = data ? data.searchMyCatalogProducts : { products: [], offset: 0, total: 0 };

    return { loading, error, products, offset, total }; 
}

const useSearchCatalogProductsManual = () => {
  const [loadSearchCatalogProducts, { loading: loadingSearchCatalogProducts, error: searchCatalogProductsError, data: SearchCatalogProductsData }] = useManualQuery(SearchCatalogProductsQuery);
  const { products, offset, total } = SearchCatalogProductsData ? SearchCatalogProductsData.searchMyCatalogProducts : { products: [], offset: 0, total: 0 };
  
  return { loadSearchCatalogProducts, loadingSearchCatalogProducts, searchCatalogProductsError, products, offset, total }; 
}

export {
  useSearchCatalogProducts,
  useSearchCatalogProductsManual,
  SearchCatalogProductsQuery,
};