import './App.css';
import React, { useState, useEffect } from 'react';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottonMenu';
import ProductsPage from './components/ProductsPage';
import MessagesPage from './components/MessagesPage';
import UsersPage from './components/usersPage/UsersPage.tsx';
import { Main } from 'components/LabelSection';
import { Routes, Route } from 'react-router-dom';

import EditUserInfo from 'components/users/EditUserInfo';
import { useFetchUserInfoQuery } from 'store';
import { useQueryParams } from 'hooks/useQueryParams';
import { PromptDialog } from 'components/utils/PromptDialog';
import HomePage from 'components/usersPage/HomePage';
import OrderPage from 'components/Orders/OrderPage';
import SettingPage from 'components/Settings/SettingPage';

function App() {
  const queryTokenInfo = useQueryParams();
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const {
    error: userInfoError,
    isLoading: userInfoLoading,
    data: userInfoData,
  } = useFetchUserInfoQuery(queryTokenInfo);

  if (!userInfoError && userInfoLoading) {
    // show load screen
    return <>Loading...</>;
  } else if (userInfoError && !userInfoLoading) {
    if (userInfoError.status === 401) {
      // unauthorized / no-session
      window.location.replace('https://login.atibo.io');
    }
  }

  if (
    !userInfoError &&
    !userInfoLoading &&
    userInfoData &&
    (!userInfoData.username ||
      !userInfoData.firstname ||
      !userInfoData.lastname)
  ) {
    return <EditUserInfo userInfo={userInfoData} />;
  }

  return (
    <div className='h-screen'>
      <div className={`flex h-full`}>
        <div
          className={`p-5 pt-4 bg-gray-100 text-white relative duration-500 ${
            open ? 'w-64' : 'w-20'
          }`}
        >
          <div className='flex w-full justify-end left-3 top-1 absolute transition duration-500'>
            <span
              className='cursor-pointer border-solid border-2 border-primary-200 rounded-full bg-white'
              onClick={() => setOpen(!open)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 text-primary-700 p-1'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                />
              </svg>
            </span>
          </div>
          <div className='pt-4 text-white duration-500'>
            <span
              className={`absolute -right-4 ${!open && 'rotate-180'}`}
            ></span>
            <SideMenu
              selectedOption={selected}
              userInfo={userInfoData}
              onChangeSelection={(value) => setSelected(value)}
              open={open}
            />
            {/* <BottomMenu userInfo={userInfoData} open={open} /> */}
          </div>
        </div>
        <div className='p-7 w-full'>
          <Routes>
            <Route
              path='/'
              element={<HomePage userInfoData={userInfoData} />}
            />
            <Route path='articulos' element={<ProductsPage />} />
            <Route path='mensajes' element={<MessagesPage />} />
            <Route path='etiquetas' element={<Main />} />
            <Route path='ordenes' element={<OrderPage />} />
            <Route path='configuracion' element={<SettingPage />} />
            {userInfoData?.isAdmin && userInfoData?.storeId && (
              <Route
                path='usuarios'
                element={<UsersPage userInfoData={userInfoData} />}
              />
            )}
          </Routes>
        </div>
      </div>
      <PromptDialog />
    </div>
  );
}

export default App;
