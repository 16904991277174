import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { Provider } from 'react-redux';
import { store } from 'store';
import { BrowserRouter } from 'react-router-dom';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const token = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).sessionInfo?.token : window.location.href.includes('access_token=') ? window.location.href.split('access_token=')[1].split('&')[0] : null;

const connectionParams = {
  Authorization: `Bearer ${token}`,
};

const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_SOCKET_URL, {
  reconnect: true,
  connectionParams
});

const graphQLClient = new GraphQLClient({
  url: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  subscriptionClient,
  fetch: async (url, init) => {
    const token = localStorage.getItem('session')
    ? JSON.parse(localStorage.getItem('session')).sessionInfo?.token
    : window.location.pathname.includes('access_token=')
    ? window.location.pathname.split('access_token=')[1].split('&')[0]
    : null;

    init.headers.Authorization = `Bearer ${token}`;
    const response = await fetch(url, init);
    return response;
  },
  connectionParams: {
    setTimeout: 120000,
  }
});

console.log('process.env.REACT_APP_BACKEND_URL', process.env.REACT_APP_BACKEND_URL);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClientContext.Provider value={graphQLClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ClientContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
