import { useGetCategoriesFromML } from 'hooks/useGetCategoriesFromML';
import React, { useEffect, useState } from 'react';
import Select, { ActionMeta } from 'react-select';

export interface LevelOption {
  value: string;
  label: string;
}

function NestedSelector({
  initialValues, onCategoryWithChildrenSelected: onCategorySelected, onFinalCategorySelected,
}: {
  initialValues: string[];
  onCategoryWithChildrenSelected: (val: string) => void;
  onFinalCategorySelected: (path: string[]) => void;
}) {
  const [levelOptionGroups, setLevelOptionGroups] = useState<LevelOption[][]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPath, setSelectedPath] = useState<string[]>(initialValues);

  const { getCategoriesList } = useGetCategoriesFromML();

  useEffect(() => {
    const initialCategoryFetching = async () => {
      for (const value of ['', ...selectedPath]) {
        const data = await getCategoriesList(value);
        setLevelOptionGroups((prev) => [...prev, data]);
      }
      setLoading(false);
      onFinalCategorySelected(initialValues);
    };
    initialCategoryFetching();
  }, [initialValues]);

  useEffect(() => {
    const fetchCategory = async () => {
      const data = await getCategoriesList(selectedPath[selectedPath.length - 1]);
      setLevelOptionGroups(prev => [...prev.slice(0, selectedPath.length), [...data]]);
      if (data.length === 0) {
        onFinalCategorySelected(selectedPath);
      } else {
        onCategorySelected(selectedPath[selectedPath.length - 1]);
      }
    };
    if (!loading && selectedPath.length > 0) {
      fetchCategory();
    }
  }, [selectedPath]);

  return loading ? <div>cargando...</div> : (
    <>
      {levelOptionGroups.map((levelOptionGroup, groupIndex) => {
        return (
          levelOptionGroup.length > 0 && <Select
            key={levelOptionGroup?.[0]?.value || groupIndex}
            className='my-1'
            value={levelOptionGroup.find((option) => option.value === selectedPath[groupIndex])}
            onChange={(value: any, actionMeta: ActionMeta<any>) => {
              if (actionMeta.action === 'select-option') {
                setSelectedPath([...selectedPath.slice(0, groupIndex), value.value]);
                onCategorySelected(value.value);
              }
            }}
            placeholder={'Seleccione'}
            isClearable={false}
            options={levelOptionGroup} />
        );
      })}
    </>
  );
};

export default NestedSelector;