interface IValidatorType {
  fn: (param: any) => boolean;
  params: any;
}

const VALID_IMAGE_FORMAT = ['jpeg', 'jpg', 'png', 'gif'];
const formatValidator = (name: string): boolean => {
  // According to https://react-dropzone.org/#!/Accepting%20specific%20file%20types
  // some browser may not apply validation format for useDropzone
  // this function adds a simple additional validation step for filename/extension
  return VALID_IMAGE_FORMAT.some((ext) =>
    new RegExp(`.${ext}$`, 'i').test(name)
  );
};

const MIN_WIDTH = 500;
const MAX_WIDTH = 2500;
const MIN_HEIGHT = 500;
const MAX_HEIGHT = 2500;
const dimensionValidator = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): boolean => {
  return (
    width >= MIN_WIDTH &&
    width <= MAX_WIDTH &&
    height >= MIN_HEIGHT &&
    height <= MAX_HEIGHT
  );
};

const MAX_IMAGE_KB_SIZE = 500 * 1024;
const sizeImageValidator = (size: number): boolean => {
  return size <= MAX_IMAGE_KB_SIZE;
};

function useImageValidation(image: any) {
  // image OBJ {
  //   name (string), size (number), src (base64), width (number), height (number), _id
  // }

  // validators to implement:
  //  - sync validators
  //      -> formato de archivo
  //      -> dimensiones
  //      -> peso
  //  - async validators
  //      -> qr codes
  //      -> url strings

  // TODO: This hoook should implement AsyncValidation using next lines
  // const [validated, setValidated] = useState(false);
  // const [processing, setProcessing] = useState(false);
  // const [errors, setErrors] = useState(false);

  const syncValidators: IValidatorType[] = [
    { fn: formatValidator, params: image.name },
    {
      fn: dimensionValidator,
      params: { width: image.width, height: image.height },
    },
    { fn: sizeImageValidator, params: image.size },
  ];
  return syncValidators.every((validatorObj: IValidatorType) => validatorObj.fn(validatorObj.params));
}

export { useImageValidation, VALID_IMAGE_FORMAT };
