import { useQuery, useManualQuery } from 'graphql-hooks';

export const GET_AMAZON_PRODUCT_BY_ASIN_QUERY = `
    query getAmazonProductByAsin($asin: String!) {
        getAmazonProductByAsin(asin: $asin) {
            asin
            title
            images
        }
    }
`;

export const useGetAmazonProductByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(GET_AMAZON_PRODUCT_BY_ASIN_QUERY, {
        variables: {
            asin
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetAmazonProductByAsinManual = () => {
    const [getAmazonProductByAsin, { loading, data, error }] = useManualQuery(GET_AMAZON_PRODUCT_BY_ASIN_QUERY);
    return {
        loading,
        error,
        data,
        getAmazonProductByAsin
    }
}