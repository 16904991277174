import React, { useEffect, useState } from 'react';
import { useSearchElegibleProductsManual } from '../hooks/useSearchElegibleProducts';
import { ElegibleProductListItem } from './ElegibleProductListItem';
import { useBottomReached } from '../hooks/useBottomReached';
import { CompareElegibleProductModal } from './CompareElegibleProductModal';
import _ from 'lodash';
import { SearchBox } from './SearchBox';

export const ElegibleProductsList = ({
  query: queryStr,
  setSelectedTabNumber,
}) => {
  const [query, setQuery] = useState(queryStr);
  const { searchElegibleProducts, loading, error, products, total } =
    useSearchElegibleProductsManual();
  const [page, setPage] = useState(0);
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const bottomOfScreenReached = useBottomReached();
  const pageSize = 10;
  const lastPage = Math.floor(total / pageSize);

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);
    searchElegibleProducts({
      variables: { query, offset: page * pageSize, limit: pageSize },
    });
  }, [query]);

  useEffect(() => {
    searchElegibleProducts({
      variables: {
        query,
        offset: page * pageSize,
        limit: pageSize,
      },
    });
  }, [page]);

  useEffect(() => {
    if (products) {
      setLoadedProducts((loadedProducts) => loadedProducts.concat(products));
    }
  }, [products]);

  useEffect(() => {
    if (bottomOfScreenReached && products && !loading && page < lastPage) {
      setPage(page + 1);
    }
  }, [bottomOfScreenReached]);

  const onProductSelected = (product) => {
    setSelectedProduct(product);
  };

  return  <div>
        { selectedProduct && <CompareElegibleProductModal product={selectedProduct} onClose={() => setSelectedProduct(null)} /> }
      <div className='w-full h-[calc(100% - 50px)] flex-col justify-start items-start gap-6 inline-flex'>
        <div className='self-stretch h-[60px] justify-start items-center gap-6 inline-flex'>
          <div className='w-[880px] self-stretch p-2.5 justify-start items-center gap-2 flex'>
            <div className='grow shrink basis-0 self-stretch justify-start items-center gap-2 flex'>
              <div
                className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'
                onClick={() => setSelectedTabNumber(0)}
              >
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Todos los articulos
                </div>
              </div>
              <div className='px-3 py-2 bg-purple-50 rounded-md justify-center items-center gap-2 flex'>
                <div className="text-violet-700 text-sm font-semibold font-['Inter'] leading-tight">
                  Articulos elegibles
                </div>
              </div>
              <div
                className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'
                onClick={() => setSelectedTabNumber(2)}
              >
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                Estrategia de optimizacion
                </div>
              </div>
              <div className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'>
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Activos
                </div>
              </div>
            </div>
          </div>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                    <SearchBox
                      query={query}
                      onQueryChanged={_.debounce(setQuery, 1000)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[833px] bg-white flex-col justify-start items-start flex'>
          <div className='w-full h-full bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'>
            <div className='self-stretch h-[69px] bg-white flex-col justify-start items-start gap-5 flex'>
              <div className='self-stretch px-4 py-5 justify-start items-start gap-4 inline-flex'>
                <div className='grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex'>
                  <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                    <div className="text-gray-900 text-lg font-semibold font-['Inter'] leading-7">
                      Articulos elegibles para catalogo
                    </div>
                    {loading ? (
                      <div>
                        <svg
                          className='animate-spin -ml-1 mr-3 h-5 w-5 text-primary-700'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <div className='px-2 py-0.5 bg-purple-50 rounded-2xl justify-start items-center flex'>
                        <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
                          {loadedProducts
                            ? loadedProducts.length + ' articulos'
                            : '0 articulos'}
                        </div>
                      </div>
                    )}
                    {error && (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 text-orange-700'
                      >
                        <title>
                          {error.httpError?.statusText}
                        </title>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                        />
                      </svg>
                    )}
                    <div className='ml-[36rem]'></div>
                  </div>
                </div>
                <div className='flex-col justify-start items-start inline-flex'>
                  <div className='w-5 h-5 relative'></div>
                </div>
              </div>
            </div>
            <div className='self-stretch bg-white justify-start items-start inline-flex'>
              <div className='w-full flex-col justify-start items-start inline-flex'>
                <div className='self-stretch h-11 px-6 py-3 bg-gray-50 border-b border-gray-200 justify-start items-center gap-3 inline-flex'>
                  <div className='w-full justify-start items-center gap-1 flex'>
                    <div className="w-[8%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Imagen
                    </div>
                    <div className="w-[42%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Titulo
                    </div>
                    <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Precio
                    </div>
                    <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Disponibles
                    </div>
                    <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Vendidos
                    </div>
                    <div className="w-[15%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]"></div>
                  </div>
                </div>
                {loadedProducts.map((product, key) => (
                  <div className='w-full h-[72px] px-4 py-2 border-b hover:bg-gray-50 border-gray-200 justify-start items-center gap-3 inline-flex'>
                    <div className='w-[8%]'>
                      <img
                        className='w-[75px] h-14 rounded-[10px]'
                        src={product.details.thumbnail}
                        alt={product.details.title}
                      />
                    </div>
                    <div className='w-[40%]'>
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        <a href={product.details.permalink} target='_blank' className='cursor-pointer'>
                          {product.details.title}
                        </a>
                      </div>
                    </div>
                    <div className='w-[12%] flex justify-center'>
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {product.details.currency_id} {product.details.price}
                      </div>
                    </div>
                    <div className='w-[10%] flex justify-center'>
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {product.details.available_quantity}
                      </div>
                    </div>
                    <div className='w-[11%] flex justify-center'>
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {product.details.sold_quantity}
                      </div>
                    </div>
                    <div className='w-[15%]'>
                      <div
                        className={`pl-4 pr-1 py-0.5 bg-purple-50 rounded-2xl justify-start items-center gap-1 flex cursor-pointer`}
                        onClick={() => onProductSelected(product)}
                      >
                        <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
                          Convertir a catalogo
                        </div>
                        <div className='w-3 h-3 relative'></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
};
