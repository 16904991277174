import React, { useEffect, useState } from 'react';
import { memoize } from 'lodash';
// import useGetCategories from '../hooks/useGetCategories';

const CATEGORY_SELECTOR_PHASES = {
  INITIALIZING: 'INITIALIZING',
  SELECTING: 'SELECTING',
};

/**
 * @deprecated Use 'ProductCategorySelector' instead
 */
export const CategorySelector = ({
  preSelectedCategories = [],
  onCategorySelected,
  onResetCategorySelected,
  showTitle = true,
  selectProps = {},
}) => {
  const [categoriesTable, setCategoriesTable] = useState(preSelectedCategories);
  const [phase, setPhase] = useState(CATEGORY_SELECTOR_PHASES.INITIALIZING);

  console.log('preSelectedCategories:', preSelectedCategories);

  const loadPreSelectedCategories = async () => {
    console.log('loadPreSelectedCategories', preSelectedCategories);
    const categories = [];
    for (let category of preSelectedCategories) {
      console.log('category:', category);
      const response = await fetchCategory(category);
      const data = await response.json();
      categories.push(data.children_categories);
      console.log(categoriesTable);
    }
    console.log('categories:', categories);
    return categories;
  };

  useEffect(() => {
    if (phase === CATEGORY_SELECTOR_PHASES.INITIALIZING) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/categories`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage?.session)?.sessionInfo?.token}`,
        }
      })
        .then((response) => response.json())
        .then((data) => {
          loadPreSelectedCategories().then((categories) => {
            setCategoriesTable([data, ...categories]);
          });
        });
    }
  }, [phase]);

  const handleChange = (event, index) => {
    if (!event.target.value) {
      onResetCategorySelected && onResetCategorySelected();
    }

    fetchCategory(event.target.value)
      .then((response) => response.json())
      .then((data) => {
        const newCategories = [...categoriesTable].slice(0, index + 1);
        setCategoriesTable([...newCategories, data.children_categories]);

        if (onCategorySelected && !data?.children_categories?.length) {
          onCategorySelected(event.target.value);
        } else {
          onResetCategorySelected && onResetCategorySelected();
        }
      });
  };

  return (
    <div>
      {showTitle && <div className='font-bold'>Categoría:</div>}
      {(categoriesTable &&
        categoriesTable.length &&
        categoriesTable.map((categories, index) => (
          <div key={`${index}-categories}`}>
            {(categories?.length && (
              <div>
                <select
                  {...selectProps}
                  className='border w-full max-w-xl rounded p-2 mb-1'
                  onChange={(event) => handleChange(event, index)}
                >
                  <option value=''>Seleccionar</option>
                  {categories?.map &&
                    categories.map((category) => {
                      return (
                        <option
                          key={`${category.id}`}
                          value={category.id}
                          selected={
                            category.id === preSelectedCategories[index]
                          }
                        >
                          {category.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )) ||
              null}
          </div>
        ))) ||
        null}
    </div>
  );
};

const fetchCategory = memoize(async (category) => {
  return await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/categories/${category}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage?.session)?.sessionInfo?.token}`,
      }
    }
  );
})
