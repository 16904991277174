import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSearchCatalogProductsManual } from '../hooks/useSearchCatalogProducts';
import { BidOnProductModal } from './BidOnProductModal';
import { useBottomReached } from '../hooks/useBottomReached';
import { SearchBox } from './SearchBox';
import { Category } from '@mui/icons-material';
import { CategorySelector } from './CategorySelector';
import { useEditBidStatusManual } from 'hooks/useEditBidStatus';
import { DelayedSwitch } from './utils/Switch';

export const CatalogProductsList = ({ setSelectedTabNumber }) => {
  const [query, setQuery] = useState('');
  const [bidOnProductModalVisible, setBidOnProductModalVisible] =
    useState(false);
  const {
    loadSearchCatalogProducts,
    loadingSearchCatalogProducts,
    searchCatalogProductsError,
    products,
    total,
  } = useSearchCatalogProductsManual();

  const {
    updateBidStatus,
    loading: loadingBidStatus,
    error: errorBidStatus,
    bidResponse,
  } = useEditBidStatusManual();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [catalogProducts, setCatalogProducts] = useState(products);
  const [page, setPage] = useState(0);
  const bottomOfScreenReached = useBottomReached();
  const [loadedProducts, setLoadedProducts] = useState([]);
  const pageSize = 20;
  const lastPage = Math.floor(total / pageSize);

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);
    loadSearchCatalogProducts({
      variables: { query, offset: page * pageSize, limit: pageSize },
    });
  }, [query]);

  useEffect(() => {
    loadSearchCatalogProducts({
      variables: { query, offset: page * pageSize, limit: pageSize },
    });
  }, [page]);

  useEffect(() => {
    setCatalogProducts(products);
  }, [products]);

  useEffect(() => {
    if (
      bottomOfScreenReached &&
      products &&
      !loadingSearchCatalogProducts &&
      page < lastPage
    ) {
      setPage(page + 1);
    }
  }, [bottomOfScreenReached]);

  useEffect(() => {
    if (catalogProducts) {
      setLoadedProducts((loadedProducts) =>
        loadedProducts.concat(catalogProducts)
      );
    }
  }, [catalogProducts]);

  const handleBidOnProduct = (bid) => {
    const newLoadedProducts = loadedProducts.map((product) => {
      if (product.id === bid.data.bidOnProduct.productId) {
        return { ...product, currentBid: bid.data.bidOnProduct };
      }
      return product;
    });
    setLoadedProducts([...newLoadedProducts]);
    setBidOnProductModalVisible(false);
  };

  const handlerPujaOnChange = async (e) => {
    if (e.target.value) {
      const { data } = await updateBidStatus({
        variables: {
          id: e.target.value,
          active: e.target.checked,
        },
      });

      if (data) {
        const newLoadedProducts = loadedProducts.map((product) => {
          if (product.id === data.updateBidStatusById.productId) {
            product.currentBid.active = data.updateBidStatusById.active;
          }
          return product;
        });

        setLoadedProducts([...newLoadedProducts]);
      }
    }
  };

  return (
    <div>
      {/* {loadingSearchCatalogProducts && <div>Cargando...</div>} */}
      {bidOnProductModalVisible && (
        <BidOnProductModal
          product={selectedProduct}
          onCancel={() => setBidOnProductModalVisible(false)}
          onBid={handleBidOnProduct}
        />
      )}
      <div className='w-full h-[calc(100% - 50px)] flex-col justify-start items-start gap-6 inline-flex'>
        <div className='self-stretch h-[60px] justify-start items-center gap-6 inline-flex'>
          <div className='w-[880px] self-stretch p-2.5 justify-start items-center gap-2 flex'>
            <div className='grow shrink basis-0 self-stretch justify-start items-center gap-2 flex'>
              <div
                className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'
                onClick={() => setSelectedTabNumber(0)}
              >
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Todos los articulos
                </div>
              </div>
              <div
                className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'
                onClick={() => setSelectedTabNumber(1)}
              >
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Articulos elegibles
                </div>
              </div>
              <div className='px-3 py-2 bg-purple-50 rounded-md justify-center items-center gap-2 flex'>
                <div className="text-violet-700 text-sm font-semibold font-['Inter'] leading-tight">
                  Estrategia de optimizacion
                </div>
              </div>
              <div className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer'>
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Activos
                </div>
              </div>
            </div>
          </div>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                    <SearchBox
                      query={query}
                      onQueryChanged={_.debounce(setQuery, 1000)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[833px] bg-white flex-col justify-start items-start flex'>
          <div className='w-full h-full bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'>
            <div className='self-stretch h-[69px] bg-white flex-col justify-start items-start gap-5 flex'>
              <div className='self-stretch px-4 py-5 justify-start items-start gap-4 inline-flex'>
                <div className='grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex'>
                  <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                    <div className="text-gray-900 text-lg font-semibold font-['Inter'] leading-7">
                      Articulos elegibles para catalogo
                    </div>
                    {loadingSearchCatalogProducts ? (
                      <div>
                        <svg
                          className='animate-spin -ml-1 mr-3 h-5 w-5 text-primary-700'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <div className='px-2 py-0.5 bg-purple-50 rounded-2xl justify-start items-center flex'>
                        <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
                          {loadedProducts
                            ? loadedProducts.length + ' articulos'
                            : '0 articulos'}
                        </div>
                      </div>
                    )}
                    {searchCatalogProductsError && (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 text-orange-700'
                      >
                        <title>{searchCatalogProductsError}</title>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                        />
                      </svg>
                    )}
                    <div className='ml-[36rem]'></div>
                  </div>
                </div>
                <div className='flex-col justify-start items-start inline-flex'>
                  <div className='w-5 h-5 relative'></div>
                </div>
              </div>
            </div>
            <div className='self-stretch bg-white justify-start items-start inline-flex'>
              <div className='w-full flex-col justify-start items-start inline-flex'>
                <div className='self-stretch h-11 px-6 py-3 bg-gray-50 border-b border-gray-200 justify-start items-center gap-3 inline-flex'>
                  <div className='w-full justify-start items-center gap-1 flex'>
                    <div className="w-[8%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Imagen
                    </div>
                    <div className="w-[40%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Titulo
                    </div>
                    <div className="w-[14%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Precio
                    </div>
                    <div className="w-[10%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Disponibles
                    </div>
                    <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      Vendidos
                    </div>
                    <div className="w-[8%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]"></div>
                    <div className="w-[7%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      min/max
                    </div>
                  </div>
                </div>
                {loadedProducts.map((catalogProduct, idx) => (
                  <div
                    className='w-full h-[72px] px-4 py-2 border-b hover:bg-gray-50 border-gray-200 justify-start items-center gap-3 inline-flex'
                    key={idx}
                  >
                    <div className='w-[8%]'>
                      <img
                        className='w-[75px] h-14 rounded-[10px]'
                        src={catalogProduct.thumbnail}
                        alt={catalogProduct.title}
                      />
                    </div>
                    <div className='w-[40%]'>
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        <a href={catalogProduct.permalink} target='_blank'>
                          {catalogProduct.title}
                        </a>
                      </div>
                    </div>
                    <div className="w-[12%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {catalogProduct.currency_id} {catalogProduct.price}
                      </div>
                    </div>
                    <div className="w-[10%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {catalogProduct.available_quantity}
                      </div>
                    </div>
                    <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
                      <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                        {catalogProduct.sold_quantity}
                      </div>
                    </div>
                    <div className='w-[8%] flex justify-center'>
                    <DelayedSwitch
                      id='puja'
                      name='puja'
                      value={
                        catalogProduct?.currentBid?.active
                          ? catalogProduct.currentBid.id
                          : ''
                      }
                      onChange={(e) => {
                        if (catalogProduct?.currentBid?.active) {
                          handlerPujaOnChange && handlerPujaOnChange(e);
                        }else{
                          setSelectedProduct(catalogProduct);
                          setBidOnProductModalVisible(true);
                        }
                      }}
                      checked={catalogProduct?.currentBid?.active}
                    />
                    </div>
                    <div className='w-[8%] flex justify-center'>
                      <div className='flex items-center justify-center w-full'>
                        {catalogProduct.currentBid &&
                          catalogProduct?.currentBid?.active === true && (
                            <>
                              <div
                                className='text-xs text-gray-500 cursor-pointer'
                                onClick={() => {
                                  setSelectedProduct(catalogProduct);
                                  setBidOnProductModalVisible(true);
                                }}
                              >
                                ({catalogProduct.currentBid.minPrice}-
                                {catalogProduct.currentBid.maxPrice})
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
