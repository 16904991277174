import React, { useState, useEffect, useRef } from 'react';
import { useSearchMessageBuyerManual } from 'hooks/useSearchMessageBuyer';
import { useSendMessageBuyerManual } from 'hooks/useSendMessageBuyer';
import MessageBox from './MessageBox';
import UploadFileIcon from './UploadFileIcon';
import AddProductOnDemand from '../AnswerSection/addProductOnDemand/AddProductOnDemand';
import { useSearchProductAlternativesManual } from 'hooks/useSearchProductAlternatives';
import { ProviderOptions } from '../AnswerSection/addProductOnDemand/Provider';
import { SelectCatalogOrCopilot } from '../addProduct/manual/SelectCatalogOrCopilot';

const OrderMessage = ({ orderInfo, selectedLabel, setSelectedLabel }) => {
  const divMessageBox = useRef(null);
  const [nickname, setNickname] = useState();
  const [messagesBuyer, setMessagesBuyer] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [inputMessage, setInputMessage] = useState();
  const { searchMessageBuyer } = useSearchMessageBuyerManual();
  const { sendMessageToBuyer, loading: loadingSentMessage } =
    useSendMessageBuyerManual();
  const [showAddProductOnDemand, setShowAddProductOnDemand] = useState(false);
  const {
    searchProductAlternatives,
    data: productAlternativesData,
    loading: loadingProductAlternatives,
  } = useSearchProductAlternativesManual();
  const [selectedProductAlternative, setSelectedProductAlternative] = useState(
    []
  );
  const [catalogProductToAdd, setCatalogProductToAdd] = useState(null);
  const [showAddCatalogProductDialog, setShowAddCatalogProductDialog] =
    useState(false);
  const [productOptions, setProductOptions] = useState({
    products: [],
    searchText: '',
  });

  useEffect(() => {
    setProductOptions(productAlternativesData?.searchProductAlternatives);
  }, [productAlternativesData]);

  useEffect(() => {
    if (orderInfo) {
      setNickname(orderInfo.buyer.nickname);
      setInputMessage('');
      setMessagesBuyer([]);
      setSelectedLabel('');
      setStatusMessage('');
      async function messageBuyerQuery() {
        const pack_id_message = orderInfo.pack_id
          ? orderInfo.pack_id
          : orderInfo.order_id;
        const dataMessages = await searchMessageBuyer({
          variables: {
            orderId: pack_id_message,
          },
        });

        if (dataMessages) {
          setMessagesBuyer(dataMessages.data?.searchMessageBuyer?.messages);
          setStatusMessage(dataMessages.data?.searchMessageBuyer?.status);
        }
      }
      messageBuyerQuery();
    }
  }, [orderInfo]);

  useEffect(() => {
    selectedLabel && setInputMessage(selectedLabel);
  }, [selectedLabel]);

  useEffect(() => {
    if (messagesBuyer.length) {
      divMessageBox.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [messagesBuyer.length]);

  useEffect(() => {
    if (inputMessage == '++') {
      setShowAddProductOnDemand(true);
    } else {
      setShowAddProductOnDemand(false);
    }
  }, [inputMessage]);

  useEffect(() => {
    if (
      selectedProductAlternative?.id &&
      selectedProductAlternative?.id.startsWith('MLU')
    ) {
      setInputMessage(
        'https://articulo.mercadolibre.com.uy/MLU-' +
          selectedProductAlternative?.id.replace('MLU', '')
      );
    } else if (
      selectedProductAlternative?.catalogProductId &&
      selectedProductAlternative.provider === ProviderOptions.MERCADOLIBRE
    ) {
      setCatalogProductToAdd({
        id: selectedProductAlternative.catalogProductId,
        name: selectedProductAlternative.name,
        domainId: selectedProductAlternative.domainId,
      });
      setShowAddCatalogProductDialog(true);
    } else if (selectedProductAlternative?.id && !showAddProductOnDemand) {
      setShowAddProductOnDemand(true);
    } else {
      setSelectedProductAlternative(null);
    }
  }, [selectedProductAlternative]);

  const handlerSendClick = async () => {
    // setMessagesBuyer([...messagesBuyer, dataResponse]);
    const pack_id_message = orderInfo.pack_id
      ? orderInfo.pack_id
      : orderInfo.order_id;
    const dataSent = await sendMessageToBuyer({
      variables: {
        orderId: pack_id_message,
        buyerId: orderInfo.buyer.id.toString(),
        dataInput: inputMessage,
        attachments: '',
      },
    });

    if (dataSent) {
      setMessagesBuyer([
        ...messagesBuyer,
        dataSent.data.sendMessageToBuyer.messages,
      ]);
    }
    setInputMessage('');
  };

  return (
      <div className='self-stretch bg-white flex-col justify-start items-start flex h-full'>
        <div className='w-full h-full bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex'>
          <div className='block p-4 ext-sm font-normal font-["Inter"] leading-tight w-full h-full'>
            <div className='h-[6%] flex justify-between border-2 border-white border-b-gray-100'>
              <span className='text-primary-700'>{nickname}</span>
              <span className={statusMessage === 'blocked' ? 'text-red-500' : 'text-green-700'}>{statusMessage}</span>
            </div>
            {showAddProductOnDemand && !selectedProductAlternative && (
              <AddProductOnDemand
                search={''}
                list={
                  productAlternativesData?.searchProductAlternatives
                    ?.products ?? []
                }
                loading={loadingProductAlternatives}
                onCancel={() => {
                  setShowAddProductOnDemand(false);
                  setProductOptions({ products: [], searchText: '' });
                }}
                onSelected={(item) => {
                  setShowAddProductOnDemand(false);
                  setSelectedProductAlternative(item);
                }}
                onSearch={(text) =>
                  searchProductAlternatives({
                    variables: {
                      productTitle: text,
                      question: 'tienen este producto?',
                    },
                  })
                }
              />
            )}
            {selectedProductAlternative &&
              selectedProductAlternative.provider ===
                ProviderOptions.AMAZON && (
                <SelectCatalogOrCopilot
                  asin={selectedProductAlternative.id}
                  onCancel={() => setSelectedProductAlternative(null)}
                />
              )}
            <div className='h-[85%] border-2 border-white border-b-gray-100 overflow-y-auto'>
              <MessageBox
                messagesBuyer={messagesBuyer}
                orderInfo={orderInfo}
              />
              <div ref={divMessageBox} />
            </div>
            <div className='mt-2 flex'>
              <div
                className={`w-[6%] mr-1 h-full text-white rounded-full flex justify-center p-2`}
              >
                {orderInfo && (
                  <UploadFileIcon
                    orderInfo={orderInfo}
                    messagesBuyer={messagesBuyer}
                    setMessagesBuyer={setMessagesBuyer}
                  />
                )}
              </div>
              <div className='grow shrink basis-0 self-stretch flex justify-start items-end gap-3 inline-flex w-[80%]'>
                <div className='w-full h-11 flex justify-start items-start gap-1.5 flex'>
                  <div className='self-stretch px-3.5 py-2.5 bg-white rounded-full shadow border border-gray-300 justify-start items-center gap-2 inline-flex w-full'>
                    <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                      <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal w-full">
                        <input
                          className='outline-0 ml-2 w-[98%] border-0'
                          type='text'
                          placeholder='Escriba su mensaje'
                          onChange={(e) => setInputMessage(e.target.value)}
                          value={inputMessage}
                          disabled={!orderInfo ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-[20%]'>
                <div className='w-full ml-2 h-full flex'>
                  <div className={`w-[40%]`}>
                    <div
                      className={`w-[80%] h-full text-white rounded-full flex justify-center p-2 ${
                        orderInfo && inputMessage
                          ? 'bg-primary-700 cursor-pointer'
                          : 'bg-primary-200 cursor-not-allowed'
                      }`}
                      onClick={() => {
                        inputMessage && handlerSendClick();
                      }}
                    >
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          data-slot='icon'
                          className='w-6 h-6 mt-0.5'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className='w-1/2'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default OrderMessage;
