import React, { useEffect, useState } from 'react';
import { useGetDomainInfoManual } from 'hooks/useGetDomainInfo';
import { usePublishProductManual } from 'hooks/usePublishProduct';
import {
  AddCatalogProductResultError,
  AddCatalogProductResultLoading,
  AddCatalogProductResultSuccess,
} from 'components/addProduct/common/AddCatalogProductResult';
import { AddCatalogProductForm } from 'components/addProduct/catalog/AddCatalogProductForm';
import { PRODUCT_FORM_STATES } from 'components/addProduct/types/AddCatalogProduct.types';

function AddCatalogProduct({
  product,
  visible,
  onCancel,
  onPublish,
  asinPrice,
  gtin,
  asin,
  closeButtonLabel = 'Enviar link',
}: {
  product: any;
  visible: boolean;
  onCancel: () => void;
  onPublish: (newId: string) => void;
  asinPrice: number;
  gtin: string;
  asin?: string;
  closeButtonLabel?: string;
}) {
  const {
    getDomainInfo,
    loading: loadingDomainInfo,
    error: errorDomainInfo,
    domainInfo,
  } = useGetDomainInfoManual();
  const {
    publishProduct,
    loading: publishingLoading,
    error: publishingError,
  } = usePublishProductManual();

  const [showCreationProductResult, setShowCreationProductResult] =
    useState(false);

  useEffect(() => {
    if (product?.domain_id || product?.domainId) {
      getDomainInfo({
        variables: {
          domainId: product?.domain_id || product?.domainId,
        },
      });
    }
  }, [product]);

  const productName = product?.name || '';
  const productId = product?.id || '';

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleCloseAfterPublish = () => {
    if (onPublish) onPublish(String(showCreationProductResult));
  };

  let currentFormState: PRODUCT_FORM_STATES;
  if (loadingDomainInfo) {
    currentFormState = PRODUCT_FORM_STATES.SHOW_LOADING;
  } else if (errorDomainInfo) {
    currentFormState = PRODUCT_FORM_STATES.SHOW_ERROR;
  } else if (publishingLoading) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_LOADING;
  } else if (publishingError) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_ERROR;
  } else if (showCreationProductResult) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_SUCCESS;
  } else {
    currentFormState = PRODUCT_FORM_STATES.SHOW_SUCCESS;
  }

  const publishFormUI = {
    [PRODUCT_FORM_STATES.SHOW_LOADING]: (
      <div>Loading form Publish product...</div>
    ),
    [PRODUCT_FORM_STATES.SHOW_ERROR]: <div>Error on load Publish form</div>,
    [PRODUCT_FORM_STATES.SHOW_SUCCESS]: (
      <AddCatalogProductForm
        setShowCreationProductResult={setShowCreationProductResult}
        publishProduct={publishProduct}
        handleCancel={handleCancel}
        productName={productName}
        domainInfo={domainInfo}
        productId={productId}
        asinPrice={asinPrice}
        sku={asin}
      />
    ),
    [PRODUCT_FORM_STATES.SUBMIT_LOADING]: <AddCatalogProductResultLoading />,
    [PRODUCT_FORM_STATES.SUBMIT_ERROR]: <AddCatalogProductResultError />,
    [PRODUCT_FORM_STATES.SUBMIT_SUCCESS]: (
      <AddCatalogProductResultSuccess
        handleCloseOkSuccess={handleCloseAfterPublish}
        addCatalogProductInfo={String(showCreationProductResult)}
        closeButtonLabel={closeButtonLabel}
      />
    ),
  };

  return !visible ? (
    <></>
  ) : (
    <div className='p-3'>
      <div className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
        <div>
          {
            <div className='w-full max-w-xl'>
              <div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
                {publishFormUI[currentFormState]}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export { AddCatalogProduct };
