import React, { useEffect } from 'react';
import { useManualProductCopilotSubscription, ManualProductFormCopilotStatus } from 'hooks/useManualProductCopilotSubscription';
import { AddManualProduct } from './AddManualProduct';
import { ProgressDialog } from './ProgressDialog';
import ReactDOM from 'react-dom';

export const AddManualProductCopilot = ({
    asin,
    closeButtonLabel,
    onCancel,
    onPublish,
}: {
    asin: string;
    closeButtonLabel?: string;
    onCancel: () => void;
    onPublish: (permalink: string) => void;
}) => {
    const [copilotData, setCopilotData] = React.useState<any>(null);
    const [done, setDone] = React.useState(false);
    const [productInserted, setProductInserted] = React.useState(false);

    useManualProductCopilotSubscription(asin, (data) => {
        if(data?.status === ManualProductFormCopilotStatus.DONE) {
            setDone(true);
        }
        if(!done) setCopilotData(data);
    });

    return ReactDOM.createPortal(<>
        {
            copilotData?.status === ManualProductFormCopilotStatus.IN_PROGRESS || !copilotData ?
            <ProgressDialog
                title="Llenando automáticamente el formulario"
                progress={copilotData ? Math.ceil(copilotData.progress/copilotData.totalWork*100) : 0}
                onCancel={onCancel} /> :
            <AddManualProduct
                visible={!productInserted}
                asin={asin}
                closeButtonLabel={closeButtonLabel}
                onCancel={onCancel}
                onPublish={(permalink: string) => {
                    setProductInserted(true);
                    onPublish(permalink);
                }}
                titleAsinProduct={copilotData?.suggestion?.title || ''}
                price={copilotData?.suggestion?.price || 0}
                currency={copilotData?.suggestion?.currency || ''}
                productDescriptionAsin={copilotData?.suggestion?.description || ''}
                productImages={copilotData?.suggestion?.images || []}
                productName={copilotData?.suggestion?.title || ''}
                categoryPath={copilotData?.suggestion?.categoryPath || []}
                attributeValues={copilotData?.suggestion?.attributes || []} />

        }
    </>, document.getElementById('modal-root')!);
};