import React, { Fragment, useEffect, useState } from 'react';
import Table from './Table';
import LabelModal from './LabelModal';

import { useSearchLabelsManual } from 'hooks/useSearchLabels';
import { useEditLabelManual } from 'hooks/useEditLabels';
import { useAddLabelManual } from 'hooks/useAddLabel';
import { useDeleteLabelManual } from 'hooks/useDeleteLabels';
import AlertMessage from 'components/utils/AlertMessage';
import { DANGERMSGALERT } from 'components/utils/constants';

function Main() {
  const [dataLabel, setDataLabel] = useState([]);
  const [editData, setEditData] = useState({});
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [labelError, setLabelError] = useState();

  const {
    searchLabels,
    loading: loadingSearchLabels,
    error: errorSearchLabels,
  } = useSearchLabelsManual();
  const {
    addLabel,
    loading: loadingAddLabel,
    error: errorAddLabel,
  } = useAddLabelManual();
  const {
    editLabel,
    loading: loadingEditLabel,
    error: errorEditLabel,
  } = useEditLabelManual();

  const {
    deleteLabel,
    loading: loadingDeleteLabel,
    error: errorDeleteLabel,
  } = useDeleteLabelManual();

  useEffect(() => {
    const fetchDataLabels = async () => {
      const { data } = await searchLabels({
        variables: {
          query: '',
          limit: 20,
          offset: 0,
        },
      });

      if (errorSearchLabels) {
        let error = 'Error';
        if (errorSearchLabels.httpError) {
          error = `${errorSearchLabels.httpError.statusText} - para obtener las etiquetas`;
        }

        setLabelError(error);
      }

      // console.log('data de main', data);

      if (data) {
        setDataLabel(data.searchLabels.labels);
      }
    };
    fetchDataLabels();
  }, []);

  const handlerDataLabel = (info) => {
    setDataLabel([...dataLabel, info]);
    setShowLabelModal(false);
  };

  const handlerEdit = (id) => {
    // console.log('edit', id);
    setShowLabelModal(true);
    const newData = dataLabel.filter((obj) => obj.id === id);
    newData.map((d) => {
      setEditData({ id: d.id, title: d.title, description: d.description, module: d.module });
    });
  };

  const handlerSaveEdit = (info) => {
    const updatedDataIndex = dataLabel.findIndex((d) => d.id === info.id);
    dataLabel[updatedDataIndex] = info;
    setDataLabel([...dataLabel]);
    setShowLabelModal(false);
  };

  const handlerDelete = async (id) => {
    const { data } = await deleteLabel({
      variables: {
        id,
      },
    });

    if (errorDeleteLabel) {
      let error = 'Error';
      if (errorDeleteLabel.httpError) {
        error = `${errorDeleteLabel.httpError.statusText} - para eliminar la etiqueta`;
      }

      setLabelError(error);
    }

    if (data) {
      const resultId = dataLabel.findIndex((obj) => obj.id === id);

      if (resultId > -1) {
        dataLabel.splice(resultId, 1);
        setDataLabel([...dataLabel]);
      }
    }
  };

  const handlerAddLabel = () => {
    setEditData({});
    setShowLabelModal(true);
  };

  return (
    <Fragment>
      <div>
        <div className="container mx-auto rounded-xl p-8 m-10">
          <p className="text-3xl text-gray-700 font-bold mb-5">Etiquetas</p>
          <p className="text-gray-500 text-lg">
            Configura las etiquetas que apareceran en la parte superior de la
            seccion de respuestas!
          </p>
          <p className="text-gray-500 text-lg"></p>
        </div>
        {(errorSearchLabels || errorDeleteLabel) && (
          <AlertMessage
            message={labelError}
            title="Error: "
            type={DANGERMSGALERT}
          />
        )}
        {(loadingSearchLabels || loadingDeleteLabel) && (
          <span>cargando...</span>
        )}
        <Table
          data={dataLabel}
          handlerAddLabel={handlerAddLabel}
          handlerDelete={handlerDelete}
          handlerEdit={handlerEdit}
        />
        {showLabelModal && (
          <LabelModal
            handlerDataLabel={handlerDataLabel}
            setShowLabelModal={setShowLabelModal}
            editData={editData}
            handlerSaveEdit={handlerSaveEdit}
            addLabel={addLabel}
            loadingAddLabel={loadingAddLabel}
            errorAddLabel={errorAddLabel}
            loadingEditLabel={loadingEditLabel}
            errorEditLabel={errorEditLabel}
            editLabel={editLabel}
          />
        )}
      </div>
    </Fragment>
  );
}

export default Main;
