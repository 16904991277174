import { useManualQuery } from 'graphql-hooks';

const AddSettingsMutation = `
  mutation addSettings($cuota: Int, $meli: String, $dias: String, $porcentaje_ganancia: JSON, $costo_envio: JSON){
    addSettings(cuota: $cuota, meli: $meli, dias: $dias, porcentaje_ganancia: $porcentaje_ganancia, costo_envio: $costo_envio){
      settings {
        id
        cuota
        meli
        dias
        porcentaje_ganancia
        costo_envio
      }
    }
  }
`;

const useAddSettingsManual = () => {
  const [addSettings, { loading, error, data: settingsResponse }] =
    useManualQuery(AddSettingsMutation);
  return { addSettings, loading, error, settingsResponse };
};

export { AddSettingsMutation, useAddSettingsManual };
