import { emailValidator } from 'hooks/useQueryParams';
import React, { useState } from 'react';
import {
  hideMessage,
  useCreateUserForStoreMutation,
  useUpdateUserPermissionsForStoreMutation,
} from 'store';
import { UserItemList } from './UsersPage';
import { useDispatch } from 'react-redux';

interface PermissionOption {
  value: string;
  label: string;
}
interface PermissionSelected {
  id: string;
}

export const PERMISSION_OPTIONS: PermissionOption[] = [
  {
    label: 'Publicación Manual',
    value: 'manual_publishing',
  },
  {
    label: 'Publicación de Catálogo',
    value: 'catalog_publishing',
  },
  {
    label: 'Lectura de Mensajes',
    value: 'message_reading',
  },
  {
    label: 'Respuesta a Mensajes',
    value: 'message_writing',
  },
];

export const EditUserPermissions = (userItemList: UserItemList) => {
  const FnEditUserPerm = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string>('');
    const [perm, setPerm] = useState<Set<string>>(
      new Set(userItemList.permissions)
    );
    const [updatePermissions] = useUpdateUserPermissionsForStoreMutation();

    const changeCheck = (evt: any) => {
      const newSet = new Set(perm);
      if (!evt.target.checked) {
        newSet.delete(evt.target.value);
        setPerm(newSet);
      } else {
        newSet.add(evt.target.value);
        setError('');
      }
      setPerm(newSet);
    };

    const savePermissionsList = async () => {
      if (perm.size === 0) {
        setError('Debes seleccionar al menos un permiso');
        return;
      }
      setError('');
      try {
        await updatePermissions({
          data: {
            id: userItemList.id,
            permissions: Array.from(perm),
          },
        }).unwrap();
        dispatch(hideMessage());
      } catch (err: any) {
        console.log('>> Error on update permissions: ', err);
        setError(err?.data?.message);
      }
    };

    return (
      <div>
        <h3 className='font-semibold text-xl mt-8 mb-3'>
          Editar permisos:{' '}
          <span className='text-primary-700'>{userItemList.email}</span>
        </h3>
        <div className='text-left mt-6'>
          <div className='pl-4 grid grid-cols-2 gap-2'>
            {PERMISSION_OPTIONS.map((popt: PermissionOption, idx: number) => {
              return (
                <label key={`perm-${idx}`}>
                  <input
                    type='checkbox'
                    name='perm'
                    checked={perm.has(popt.value) ? true : false}
                    className='permission-check'
                    value={popt.value}
                    onChange={(evt) => {
                      changeCheck(evt);
                    }}
                  />{' '}
                  {popt.label}
                </label>
              );
            })}
          </div>
        </div>
        <div className='mt-8'>
          <button
            data-modal-hide='popup-modal'
            type='button'
            className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
            onClick={() => {
              savePermissionsList();
            }}
          >
            Guardar cambios
          </button>
          {error && (
            <div className='text-center text-red-500 text-sm mt-4'>{error}</div>
          )}
        </div>
      </div>
    );
  };

  return FnEditUserPerm;
};

function AddUser() {
  const [email, setEmail] = useState<string | undefined>('');
  const [emailVerify, setEmailVerify] = useState<string | undefined>('');
  const [pass, setPass] = useState<string | undefined>('');
  const [passVerify, setPassVerify] = useState<string | undefined>('');
  const [permissions, setPermissions] = useState<Set<PermissionSelected>>(
    new Set()
  );
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [createUserForStore] = useCreateUserForStoreMutation();

  const handleFormSubmit = async (evt: any) => {
    evt && evt.preventDefault && evt.preventDefault();
    let error = '';
    if (
      !email ||
      !emailVerify ||
      email !== emailVerify ||
      !emailValidator.test(email)
    ) {
      error = 'Ingrese un E-mail válido';
    } else if (!pass || !passVerify || pass !== passVerify) {
      error = 'Ingrese una clave válida';
    } else if (permissions.size === 0) {
      error = 'Seleccione al menos un permiso';
    } else {
    }

    if (error) {
      setError(error);
      return;
    } else {
      setError('');
    }

    try {
      setLoading(true);
      await createUserForStore({
        data: {
          email,
          password: pass,
          permissions: Array.from(permissions),
        },
      }).unwrap();
      setLoading(false);
      setEmail('');
      setEmailVerify('');
      setPass('');
      setPassVerify('');
      setPermissions(new Set());
      document.querySelectorAll('.permission-check').forEach((elem: any) => {
        elem.checked = false;
      });
    } catch (err: any) {
      setError(err.data?.message);
    }
  };

  const changeCheck = (evt: any) => {
    const newSet = new Set(permissions);
    if (!evt.target.checked) {
      newSet.delete(evt.target.value);
      setPermissions(newSet);
    } else {
      newSet.add(evt.target.value);
    }
    setPermissions(newSet);
  };

  return (
    <div className='m-3 relative border border-gray-500 pt-1'>
      <div className='font-semibold text-xl mb-3 absolute -top-5 p-1 left-2 bg-white'>
        Crear Usuario
      </div>
      <div className='p-5 pt-3'>
        <form onSubmit={handleFormSubmit}>
          <div className='flex flex-col'>
            <div className='flex gap-1 justify-between'>
              <input
                type={'email'}
                placeholder='E-mail'
                autoComplete='false'
                className='border border-gray-600 p-1 rounded mt-4 mb-2 text-sm w-42'
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <input
                type={'text'}
                placeholder='Re-ingresar E-mail'
                autoComplete='false'
                className='border border-gray-600 p-1 rounded mt-4 mb-2 text-sm w-42'
                value={emailVerify}
                onChange={(evt) => setEmailVerify(evt.target.value)}
              />
              <input
                type={'password'}
                placeholder='Clave'
                autoComplete='false'
                className='border border-gray-600 p-1 rounded mt-4 mb-2 text-sm w-42'
                value={pass}
                onChange={(evt) => setPass(evt.target.value)}
              />
              <input
                type={'password'}
                placeholder='Re-ingresar clave'
                autoComplete='false'
                className='border border-gray-600 p-1 rounded mt-4 mb-2 text-sm w-42'
                value={passVerify}
                onChange={(evt) => setPassVerify(evt.target.value)}
              />
            </div>
            <div>
              Permisos:
              <div className='pl-4 grid grid-cols-2 gap-2'>
                {PERMISSION_OPTIONS.map(
                  (popt: PermissionOption, idx: number) => {
                    return (
                      <label key={`perm-${idx}`}>
                        <input
                          type='checkbox'
                          name='perm'
                          className='permission-check'
                          value={popt.value}
                          onChange={(evt) => {
                            changeCheck(evt);
                          }}
                        />{' '}
                        {popt.label}
                      </label>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className='text-center mt-3'>
            <button
              type='submit'
              className='bg-primary-700 text-white px-2 py-1  hover:bg-primary-500 rounded'
              disabled={loading}
            >
              Agregar Usuario
            </button>
            {error && (
              <div className='text-center text-red-500 text-sm mt-4'>
                {error}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUser;
