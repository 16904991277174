import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React, { useEffect } from 'react';
import { AoTextInputProps } from '../types/MLForm.types';
import { Control, Controller } from 'react-hook-form';

function AoComboSelect({ opts, label, config, attributes, value }: AoTextInputProps) {
  const [selectedOption, setSelectedOption] = React.useState();
  const id = attributes.id;
  const isRequired = attributes.tags.includes('required');
  const options = attributes?.values?.map((val: any) => ({
    label: val.name,
    value: val.id,
  }));
  let onChangeField: any = null;

  const valueType = attributes.value_type;

  if(!options.some((opt: any) => opt.label === value) && value) {
    options.push({
      label: value,
      value,
    })
  }

  // useEffect(() => {
  //   if(options) {
  //     setSelectedOption(options.find((opt: any) => attributes.value_type === "boolean" ? opt.label === value : opt.value === value)?.label)
  //   }
  // }, [selectedOption])

  useEffect(() => {
    if(options) {
      const newValue = options.find((opt: any) => opt.label === value)
      setSelectedOption(newValue);
      if(onChangeField && newValue) onChangeField(newValue.value);
    }
  }, []);

  const control: Control<any> = opts?.control ? opts.control : null;

  return (
    <div className='my-2 grow-0 md:basis-1/3 lg:basis-1/4 px-1'>
      <label
        className='block text-gray-700 text-sm font-bold'
        htmlFor={id}
      >
        {label + (valueType === 'boolean' ? ' (Sí/No)' : '')}
        {isRequired && <span className='text-red-700'>*</span>}
      </label>

      <Controller
        control={control}
        name={id}
        rules={{ required: isRequired }}
        render={({
          field: { onChange, onBlur, ref },
          fieldState: { error },
        }) => {
          onChangeField = onChange;

          return (
            <>
              {config?.allow_custom_value ? (
                <CreatableSelect
                  ref={ref}
                  formatCreateLabel={(inputValue: string) =>
                    `Agregar "${inputValue}"`
                  }
                  onChange={(newValue) => {
                    if(newValue) {
                      setSelectedOption(options.find((opt: any) => opt.value === newValue));
                      onChange(newValue);
                    }
                  }}
                  onBlur={onBlur}
                  value={selectedOption}
                  inputId={id}
                  placeholder={'Seleccione'}
                  options={options}
                  isClearable
                />
              ) : (
                <Select
                  ref={ref}
                  onChange={(newValue) => {
                    if(newValue) {
                      setSelectedOption(options.find((opt: any) => opt.value === newValue));
                      onChange(newValue);
                    }
                  }}
                  onBlur={onBlur}
                  value={selectedOption}
                  inputId={id}
                  placeholder={'Seleccione'}
                  options={options}
                  isClearable
                />
              )}
              {error && (
                <p className='text-red-500 text-xs italic'>
                  Seleccione {label}
                </p>
              )}
            </>
          );
        }}
      />

      <div className='text-gray-500 text-xs'>
        {config?.hint || config?.tooltip}
      </div>
    </div>
  );
}

export { AoComboSelect };
