import React, { MouseEvent, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AddManualProductFormAttributes } from './AddManualProductFormAttributes';
import { AddManualProductFormControls } from './AddManualProductFormControls';
import { ImageSelector } from '../../ImageSelector/ImageSelector';
import { ImageListItem } from '../../ImageSelector/ImageSelector.types';
import { AddProductBasicFormValues } from '../types/AddCatalogProduct.types';
import { FetchData } from 'graphql-hooks';
import { get, uniq } from 'lodash';

const formDefaultValues: AddProductBasicFormValues = {
  productCategory: '',
  productDescription: '',
  productName: '',
  currency: '',
  price: 0,
  stock: 12,
  productImages: [],
  attributes: '',
};

function AddManualProductForm({
  setShowCreationProductResult,
  publishProduct,
  handleCancel,
  asin,
  productName,
  titleAsinProduct,
  price = 0,
  productDescriptionAsin,
  productImages,
  onProductImagesChanged,
  categoryPath: initialCategoryPath = [],
  attributeValues = [],
}: {
  setShowCreationProductResult: (id: boolean) => void;
  publishProduct: FetchData<any, object, object>;
  handleCancel: (evt: MouseEvent<HTMLButtonElement>) => void;
  asin?: string;
  productName: string;
  titleAsinProduct: string;
  price: number;
  productDescriptionAsin:string;
  productImages:any;
  onProductImagesChanged: (images: any) => void;
  categoryPath?: string[];
  attributeValues?: {name: string, value: string | number}[];
}) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {...formDefaultValues, productName},
  });

  const [attrSections, setAttrSections] = useState<any[]>(attributeValues); // TODO: Create group[] type
  const [productCategory, setProductCategory] = useState<string | undefined>();
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
  const [updatedImages, setUpdatedImages] = useState<string[]>([]);
  const [preloadedImages, setPreloadedImages] = useState<string[]>([]);
  const [categoryPath, setCategoryPath] = useState<string[]>(initialCategoryPath);

  useEffect(() => {
    const imageGroupsIds = productImages.reduce((acc: any, curr: any) => {
      const id = curr?.link?.split('NP_')?.[1]?.split('-F.')?.[0];
      if(!acc.includes(id)) {
        acc.push(id);
      }
      return acc;
    }, []);
    const imagesByGroup = imageGroupsIds.map((id: string) => productImages.find((img: any) => img.link.includes(`_${id}-`))?.link);
    setPreloadedImages(imagesByGroup || [])
  }, []);

  useEffect(() => {
    if(getValues('productCategory') !== productCategory) setValue('productCategory', productCategory ?? '');
    clearErrors();
    //eslint-disable-next-line
  }, [productCategory]);

  useEffect(() => {
    setValue('productName', titleAsinProduct?.slice(0, 60));
  }, [titleAsinProduct]);

  useEffect(()=>{
    setValue('productDescription', productDescriptionAsin)
  }, [productDescriptionAsin])

  const onSubmitForm = async (formData: FieldValues) => {
    const {
      currency,
      price,
      productCategory,
      productDescription,
      productName,
      stock,
    } = formData;
    const attributes = Object.keys(formData)
      .filter((key) => key === key.toUpperCase() && formData[key])
      .reduce((acc: any, key) => {
        acc.push({
          id: key,
          value_name: formData[key],
        });
        return acc;
      }, []);

    const pictures = [...updatedImages, ...preloadedImages].filter(picture => picture?.includes('-F.jpg')).map((url) => ({ source: url })).slice(0, 12);

    try {
      const payload = {
        title: productName,
        currencyId: currency,
        price: parseFloat(price),
        categoryId: productCategory,
        description: productDescription,
        stock: parseInt(stock),
        attributes: attributes.map((attr: any) => ({
          id: attr.id,
          value_name: attr.value_name?.label ? attr.value_name.label : String(attr.value_name) // TODO: Fix this in the right place
        })),
        pictures,
        sku: asin,
      };

      console.log('>> onSubmit Manual Product - formData: ', payload);

      // validate images array
      if (!pictures.length) {
        //TODO: Define/show UI error
        throw new Error('Missing images for product with manual publish');
      }

      const { data } = await publishProduct({
        variables: payload,
      });

      console.log('>> responsePublish: ', data);
      const newProductId = data?.publishProductManual?.id;
      setShowCreationProductResult(newProductId);
    } catch (err) {
      console.error('>> Error onSubmit: ', err);
    }
  };

  const handleImageListChanged = (list: ImageListItem[]) => {
    const images = list.map((item) => item.variations[0].url);
    setUpdatedImages(images);
  };

  const handleRemoveUploadedImage = (imageUrlImage: string) => {
    const firstItem = updatedImages.indexOf(imageUrlImage);
    if (firstItem > -1) {
      const newUpdatesImages = [...updatedImages];
      newUpdatesImages.splice(firstItem, 1);
      setUpdatedImages(newUpdatesImages);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className='text-xl font-bold mb-4 text-center'>
        Publicar nuevo producto {asin && <a href={`https://www.amazon.com/dp/${asin}`} target='_blank' rel='noreferrer'>{asin}</a>}
      </div>
      <div className=''>
        <div className='sm:flex justify-between gap-5'>
          <ImageSelector
            onImageListChanged={handleImageListChanged}
            onRemoveUploadedImage={handleRemoveUploadedImage}
            productImages={productImages}
            onProductImagesChanged={(images : any) => {
              const imageGroupsKeys = images.reduce((acc: any, curr: any) => {
                if(!acc.includes(curr.variant)) {
                  acc.push(curr.variant);
                }
                return acc;
              }, []);
              const imagesByGroup = imageGroupsKeys.map((key: string) => images.find((img: any) => img.variant === key)?.link);
              setPreloadedImages(imagesByGroup || [])
            }}
          />
          <AddManualProductFormControls
            register={register}
            setValue={setValue}
            productName={productName}
            productCategory={productCategory}
            setProductCategory={setProductCategory}
            setCategoryPath={setCategoryPath}
            errors={errors}
            price={price}
            currencyOptions={currencyOptions}
            setCurrencyOptions={setCurrencyOptions}
            categoryPath={categoryPath ?? []}
            attributeValues={attributeValues}
          />
        </div>
        <AddManualProductFormAttributes
          categoryId={productCategory}
          control={control}
          attrSections={attrSections}
          setAttrSections={setAttrSections}
          attributeValues={attributeValues}
          getValues={getValues}
          asin={asin}
        />
        <div className='flex items-center justify-end gap-3'>
          <button
            type='button'
            className='bg-gray-200 rounded-md px-2 py-1 mr-2 cursor-pointer border'
            onClick={handleCancel}
          >
            Cancelar
          </button>
          <button
            className={`text-white rounded-md px-2 py-1 mr-2 ${
              !productCategory ? 'bg-primary-200' : 'bg-primary-700'
            }`}
            type='submit'
            disabled={!productCategory}
          >
            Publicar
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddManualProductForm;
