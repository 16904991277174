import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadImageToMLManual } from 'hooks/useUploadImageToML';
import {
  useImageValidation,
  VALID_IMAGE_FORMAT,
} from 'hooks/useImageValidation';
import _ from 'lodash';

const ImageValidator = ({ image, onUploaded, onDelete }) => {
  // console.log('image en validator', image);
  const validatedImage = useImageValidation(image);

  if (!validatedImage && !image.preloadedImages) {
    return <div>Invalid Image</div>;
  }

  return (
    image.preloadedImages ?
    <div className='relative'>
      <img alt='' src={image.link} />
      <div
        className='absolute top-0 right-0 text-sm p-1 bg-white opacity-50 font-bold cursor-pointer'
        onClick={() => onDelete(image.id, image.link)}
      >
        X
      </div>
    </div> :  
    <ImageUploader
      base64={image.src}
      onUploaded={onUploaded}
      onDelete={onDelete}
      image={image}
    />
  );
};

const ImageUploader = ({ base64, onUploaded, onDelete, image }) => {
  const [srcImage, setSrcImage] = useState();
  const [srcFirstVariation, setSrcFirstVariation] = useState();
  const { loading, error, data: uploadedImage, uploadImageToML } = useUploadImageToMLManual();
  const dataImages = image.preloadedImages ? image : uploadedImage;

  useEffect(() => {
    if (uploadedImage) onUploaded(uploadedImage);
  }, [uploadedImage]);

  useEffect(() => {
    if (dataImages) {
      setSrcImage(
        dataImages.variations.find((variation) => variation.size === '90x90')
          .url
      );
      setSrcFirstVariation(dataImages.variations[0].url);
    }
  }, [dataImages]);

  
  useEffect(() => {
    if (!image.preloadedImages) {
      uploadImageToML({
        variables: {
          base64,
        },
      });
      
      if (loading) {
        return <div>{loading && <p>Loading...</p>}</div>;
      }
      
      if (error) {
        return <div>{error && <p>Error</p>}</div>;
      }
    }
  },[]);
  
  // console.info('dataimage', srcImage, srcFirstVariation);
  return (
    <div>
      {dataImages && srcImage && srcFirstVariation && (
        <div className='relative'>
          <img alt='' src={srcImage} />
          <div
            className='absolute top-0 right-0 text-sm p-1 bg-white opacity-50 font-bold cursor-pointer'
            onClick={() => onDelete(srcFirstVariation)}
          >
            X
          </div>
        </div>
      )}
    </div>
  );
};

export const ImageSelector = ({
  onImageListChanged,
  onRemoveUploadedImage,
  productImages,
  onProductImagesChanged,
}) => {
  const [images, setImages] = useState([]); // [{name, size, src, dimensions}]
  const [uploadedImages, setUploadedImages] = useState([]); // [{name, size, src, dimensions}]
  const [preloadedImages, setPreloadedImages] = useState(productImages); // [{name, size, src, dimensions}
  const [draggingOver, setDraggingOver] = useState(false);

  useEffect(() => {
    onImageListChanged(uploadedImages);
  }, [uploadedImages]);

  // useEffect(() => {
  //   if (productImages?.length > 0) {
  //     const arrNewFormatImages = [];
  //     setUploadedImages(productImages);
  //     productImages.map((imageInfo, idx) => {
  //       const srcImage = imageInfo.variations.find(
  //         (variation) => variation.size === '90x90'
  //       ).url;
  //       const imageName = `${imageInfo.id}_${idx}.jpg`;
  //       arrNewFormatImages.push({
  //         _id: _.uniqueId('image_'),
  //         name: imageName,
  //         size: 8100,
  //         src: srcImage,
  //         width: 500,
  //         height: 500,
  //         preloadedImages: imageInfo,
  //       });
  //     });
  //     setImages(arrNewFormatImages);
  //   }
  // }, []);

  const onDropCallback = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      const image = new Image();
      image.src = binaryStr;
      // console.log("image", binaryStr);
      image.onload = () => {
        setImages([
          ...images,
          {
            _id: _.uniqueId('image_'),
            name: acceptedFiles[0].name,
            size: acceptedFiles[0].size,
            src: binaryStr,
            width: image.width,
            height: image.height,
            preloadedImages: false,
          },
        ]);
      };
    };

    reader.readAsDataURL(acceptedFiles[0]);
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    accept: {
      'image/*': VALID_IMAGE_FORMAT.map((ext) => `.${ext}`),
    },
  });

  const basicProps = getInputProps();
  const inputProps = {
    ...basicProps,
    multiple: false,
    accept: VALID_IMAGE_FORMAT.map((ext) => `image/${ext}`),
  };

  const deleteImage = (position) => {
    const newImages = [...images];
    newImages.splice(position, 1);
    setImages(newImages);
  };

  const handleImageUploaded = (_id, data) => {
    const newUploadedImages = [
      ...uploadedImages,
      {
        _id,
        ...data,
      },
    ];
    setUploadedImages(newUploadedImages);
  };

  const preloadedThumbnails = preloadedImages.filter(image => image.variant === '90x90') || [];

  const handleDelete = (id, url) => {
    const newPreloadedImages = preloadedImages.filter(image => image.link !== url);
    setPreloadedImages(newPreloadedImages);
    if (onProductImagesChanged) {
      onProductImagesChanged(newPreloadedImages);
    }
  };

  // console.info('images', images);
  return (
    <div className='w-80 pl-4'>
      <div {...getRootProps()}>
        <input {...inputProps} />
        <div
          className='bg-gray-500 w-72 h-32 flex items-center justify-center text-center text-white rounded-md cursor-pointer'
          onDragEnter={() => setDraggingOver(true)}
          onDragLeave={() => setDraggingOver(false)}
          onDragEnd={() => setDraggingOver(false)}
          onDropCapture={() => setDraggingOver(false)}
          style={{ border: draggingOver ? '2px dashed white' : 'none' }}
        >
          Arrastra una imagen o presiona para agregar
        </div>
      </div>
      <div className='flex flex-wrap gap-1 justify-center mt-2'>
        {[...images, ...(preloadedThumbnails.map(image => ({...image, preloadedImages: true})))].map((image, i) => (
          <ImageValidator
            key={image._id}
            image={image}
            onUploaded={(data) => handleImageUploaded(image._id, data)}
            onDelete={(id, link) => {
              handleDelete(id, link);
              deleteImage(i);
            }}
          />
        ))}
      </div>
    </div>
  );
};