import React, { Fragment } from 'react';

function AnswerLabel({ labels, handlerLabelAnswer, elementId }) {
  const handlerPressTitle = (pressed) => {
    handlerLabelAnswer(pressed, elementId);
  };

  let labelInfo = '';

  if (labels && labels.length > 0) {

    labelInfo = labels.map((label) => (
      <div className='p-0.5'>
      <span
        key={label.id}
        className={`float-left py-1 px-4 mr-1 rounded-full text-primary-500 font-bold text-xs border border-primary-100 hover:border-primary-500 cursor-pointer bg-primary-100`}
        onClick={() => handlerPressTitle(label.description)}
      >
        {label.title}
      </span>
      </div>
    ));
  }

  return (
    <Fragment>
      <div className="mb-1 h-24 w-full p-0.5 rounded-xl shadow border-2 border-gray-100 overflow-y-auto flex flex-wrap">
        {labelInfo}
      </div>
    </Fragment>
  );
}

export default AnswerLabel;
