export const MlItemsTabs = ({onTabClick, selectedTab}) => {
  return <div className='w-[880px] self-stretch p-2.5 justify-start items-center gap-2 flex'>
    <div className='grow shrink basis-0 self-stretch justify-start items-center gap-2 flex'>
      <div
        className='px-3 py-2 bg-purple-50 rounded-md justify-center items-center gap-2 flex' onClick={() => onTabClick(0)}
      >
        <div className="text-violet-700 text-sm font-semibold font-['Inter'] leading-tight" >
          Todos los articulos
        </div>
      </div>
      <div
        className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer' onClick={() => onTabClick(1)}
      >
        <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
          Articulos elegibles
        </div>
      </div>
      <div
        className='px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer' onClick={() => onTabClick(2)}
      >
        <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
          Estrategia de optimizacion
        </div>
      </div>
      <div className='px-3 py-2 rounded-md justify-center items-center gap-2 flex'>
        <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
          Activos
        </div>
      </div>
    </div>
  </div>
}
