interface AoTextInputProps {
  opts: any;
  label: string;
  error: boolean;
  config: any;
  attributes: any;
  component?: any;
  value?: any;
  valueType?: string;
}

interface MLFormFieldProps {
  controlType: string;
  label: string;
  attributes: any;
  config: any;
  opts: any;
  component?: any;
  value?: any;
}

/**
 * To render a new type of component:
 * 1) a new ML_FORM_TYPE needs to be defined
 * 2) a new FORM_FIELD_LIST entry/map is required (FormFieldList.ts)
 */
//list of all types of components available from ML
enum ML_FORM_TYPES {
  COMBO = 'COMBO',
  TEXT_INPUT = 'TEXT_INPUT',
  BOOLEAN_INPUT = 'BOOLEAN_INPUT',
  NUMBER_UNIT_INPUT = 'NUMBER_UNIT_INPUT',
  COLOR_INPUT = 'COLOR_INPUT',
  NUMBER_INPUT = 'NUMBER_INPUT',
  LINKED_BY_CONNECTOR_INPUT = 'LINKED_BY_CONNECTOR_INPUT',
  BOOLEAN_LIST = 'BOOLEAN_LIST',
  // TEXT_OUTPUT = 'TEXT_OUTPUT', //TODO: Pending to define UI

  // TODO: Pending to have a CategoryId that includes these:
  //  - PICTURE_INPUT = 'PICTURE_INPUT',
  //  - GRID_ROW_INPUT = 'GRID_ROW_INPUT',
  //  - GRID_INPUT = 'GRID_INPUT',

  // TODO: Pending to remove following, once confirmed these are
  //       present only on vehicle and houses/aparment products.
  //  - BOOLEAN_CHECKBOX = 'BOOLEAN_CHECKBOX',
  //  - APARTMENT_NUMBER_INPUT = 'APARTMENT_NUMBER_INPUT',
  //  - HOUSE_NUMBER_INPUT = 'HOUSE_NUMBER_INPUT'
}

// MapType used to define relations MLComponents -> AoComponents
// (used in FORM_FIELD_LIST definition - FormFieldList.ts)
type FormFieldsComponents = {
  [str in ML_FORM_TYPES]: (props: AoTextInputProps) => void;
};

export {
  AoTextInputProps,
  ML_FORM_TYPES,
  FormFieldsComponents,
  MLFormFieldProps,
};
