import React from 'react';
import { AoTextInputProps } from '../types/MLForm.types';

function AoLinkedConnectorInput({
  opts,
  label,
  config,
  attributes,
  value,
}: AoTextInputProps) {
  const id = `${+new Date() * Math.random()}`;
  return (
    <div className='my-2 px-1 mr-2'>
      <label
        className='block text-gray-700 text-sm font-bold'
        htmlFor={id}
      >
        {label}
      </label>
      <div>
        {attributes
          ?.map((textInput: any, idx: number) => {
            const htmlId = idx === 0 ? { id } : {};
            return (
              <input
                key={`${id}-${textInput.name}`}
                className='shadow appearance-none border 
                  rounded py-2 px-3 text-gray-700 w-28
                  leading-tight focus:outline-none focus:shadow-outline'
                {...htmlId}
                type='number'
                placeholder={`${textInput.name} (${textInput.default_unit_id})`}
                value={value}
              />
            );
          })
          ?.reduce((prev: any, curr: any) => {
            return [...prev, curr, `${config.connector}`];
          }, [])
          ?.slice(0, -1)}
      </div>
      <div className='text-gray-500 text-xs'>{config.hint}</div>
    </div>
  );
}

export { AoLinkedConnectorInput };
