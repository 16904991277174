import React from 'react';
import { ProgressBar } from './ProgressBar';

export const ProgressDialog = ({ title, progress, onCancel }: { title: string; progress: number; onCancel: () => void; }) => {
    const boundedProgress = Math.max(0, Math.min(progress, 100));
    return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-4 rounded-md shadow-sm w-1/2 h-40 flex flex-col items-center justify-center">
            <div className="text-center font-bold text-lg mb-4">{title}</div>
            <ProgressBar progress={boundedProgress} />
            <div className="flex justify-end mt-4 w-full">
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={onCancel}>Cancelar</button>
            </div>
        </div>
    </div>;
};
