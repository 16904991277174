import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AddProductBasicFormValues } from '../types/AddCatalogProduct.types';
import NestedSelector from 'components/MLForm/NestedSelector';
//import { CategorySuggestionLoader } from 'components/MLForm/CategorySuggestionLoader';
import { useCategoryPredictor } from 'hooks/useCategoryPredictor';

const createLabel = (id: string, lbl: string, isRequired: boolean = true) => {
  return (
    <label
      className='block text-gray-700 text-sm font-bold mb-2'
      htmlFor={id}
    >
      {lbl}
      {isRequired && <span className='text-red-700'>*</span>}
    </label>
  );
};

const createInput = (id: string, opts: any, isTextArea: boolean = false) => {
  const className = `shadow appearance-none border rounded w-full py-2 px-3 
      text-gray-700 leading-tight focus:outline-none focus:shadow-outline`;
  if (isTextArea) {
    return <textarea className={className} id={id} {...opts}></textarea>;
  }
  return <input className={className} id={id} {...opts} autoComplete='off' />;
};

function AddManualProductFormControls({
  register,
  setValue,
  productName,
  productCategory,
  setProductCategory,
  setCategoryPath,
  errors,
  price,
  currencyOptions,
  setCurrencyOptions,
  categoryPath = [],
  attributeValues,
}: {
  register: UseFormRegister<AddProductBasicFormValues>;
  setValue: UseFormSetValue<AddProductBasicFormValues>;
  productName: string;
  productCategory: string | undefined;
  setProductCategory: (id: string) => void;
  setCategoryPath: (path: string[]) => void;
  price: number;
  setCurrencyOptions: (currencies: string[]) => void;
  errors: FieldErrors<any>;
  currencyOptions: string[];
  categoryPath?: string[];
  attributeValues?: { name: string; value: string | number }[];
}) {
  const [minProductCategoryPrice, setMinProductCategoryPrice] =
    useState<number>(0);
  const [currCurrencyMinCategoryPrice, setCurrCurrencyMinCategoryPrice] =
    useState<string>('');

  // const [categorySuggestionLoaderVisible, setCategorySuggestionLoaderVisible] = useState<boolean>(false);
  const [categorySuggestionUserEnteredTitle, setCategorySuggestionUserEnteredTitle] = useState<string>('');
  const [categoryVisible, setCategoryVisible] = useState<boolean>(true);
  const [reloadCategorySuggestionButtonVisible, setReloadCategorySuggestionButtonVisible] = useState<boolean>(false);
  const [selectedCategoryPath, setSelectedCategoryPath] = useState<string[]>(categoryPath ?? []);
  const { categoryPath: suggestedCategoryPath, loading: suggestedCategoryLoading, getPredictedCategory, setCategoryPath: setCategoryPathPredicted } = useCategoryPredictor();

  useEffect(() => {
    setValue('currency', 'UYU');
    setValue('price', price);
  }, []);

  const checkExistingPrice = (value: number) => {
    return (
      !productCategory || (productCategory && value >= minProductCategoryPrice)
    );
  };

  const checkPositiveNumber = (value: number) => {
    return parseFloat(String(value)) > 0;
  };

  const checkTitle = (value: string) => {
    return value?.length <= 60;
  }

  const handleCategorySelected = async (categoryId: string) => {
    try {
      if (productCategory === categoryId) return;
      const categoryInfo : any = await loadMLCategory(categoryId);
      setCurrencyOptions(categoryInfo?.settings?.currencies || []);
      setMinProductCategoryPrice(categoryInfo?.settings?.minimum_price || 0);
      setCurrCurrencyMinCategoryPrice(
        categoryInfo?.settings?.minimum_price_currency || ''
      );
      
      if (categoryId !== productCategory && categoryId) {
        const newCategoryPath = categoryInfo?.path_from_root?.map((cat: any) => cat.id);
        setCategoryVisible(false);
        setSelectedCategoryPath(newCategoryPath);
        setCategoryPath(newCategoryPath);
        setProductCategory(categoryId);
        setValue('currency', 'UYU');
        setValue('price', price);
        setTimeout(() => {
          setCategoryVisible(true);
        }, 0);
      }
    } catch (err: any) {
      console.log('Error - HandleCategorySelected: ', err);
    }
  };

  return (
    <div className='w-full'>
      <div className='flex justify-start mb-4'>
        <div className='flex-grow'>
          {createLabel('productName', 'Título')}

          {createInput('productName', {
            ...register('productName', {
              required: true,
              validate: {
                checkTitle,
              },
              onChange: (evt: any) => {
                setCategorySuggestionUserEnteredTitle(evt.target.value);
                setReloadCategorySuggestionButtonVisible(true);
              },
            }),
            onKeyDown: (evt: any) => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                setCategorySuggestionUserEnteredTitle(evt.target.value);
                setReloadCategorySuggestionButtonVisible(true);
                getPredictedCategory({
                  variables: {
                    name: categorySuggestionUserEnteredTitle,
                  },
                })
              }
            }
          })}

          {errors.productName && (
            <p className='text-red-500 text-xs italic'>
              Ingrese un título para el producto
            </p>
          )}
        </div>

        <div className='flex items-end'>
          {reloadCategorySuggestionButtonVisible && (
            <div
              className={`mx-2 ${suggestedCategoryLoading ? 'bg-primary-500' : 'bg-primary-700'} p-2 rounded-md text-white w-auto text-center select-none cursor-pointer`}
              onClick={() =>
                getPredictedCategory({
                  variables: {
                    name: categorySuggestionUserEnteredTitle,
                  },
                })
              }
            >
              {suggestedCategoryLoading ? 'cargando...' : 'Recargar categoría'}
            </div>
          )}
        </div>
      </div>
      {/* { categorySuggestionLoaderVisible && <CategorySuggestionLoader
         //Product title
        title={categorySuggestionUserEnteredTitle}
        onCategorySelected={async (id: string) => {
          handleCategorySelected(id);
        }}
        onCanceled={() => {
          setCategorySuggestionLoaderVisible(false);
          setReloadCategorySuggestionButtonVisible(false);
        }}
      />

      } */}

      <div className='mb-4'>
        {createLabel('productCategory', 'Categoría')}
        {
          categoryVisible && suggestedCategoryPath?.length < 1 && <NestedSelector
            initialValues={selectedCategoryPath ?? []}
            onFinalCategorySelected={(path: string[]) => {
              handleCategorySelected(path?.at(-1) ?? '');
            }}
            onCategoryWithChildrenSelected={(val: string) => {
              setProductCategory('');
            }}
          />
        }

        {
          suggestedCategoryPath?.length > 0 && <div>
            <div className='my-2'>{suggestedCategoryPath.map(item => item.name).join(' / ')}</div>
            <div className='flex justify-start'>
              <div className='bg-gray-200 rounded-md px-2 py-1 mr-2 select-none cursor-pointer' onClick={() => {
                setCategoryPathPredicted([]);
                setReloadCategorySuggestionButtonVisible(false);
              }}>Cancelar</div>
              <div className='text-white rounded-md px-2 py-1 mr-2 bg-primary-700 select-none cursor-pointer' onClick={() => {
                setSelectedCategoryPath(suggestedCategoryPath.map(category => category.id));
                setProductCategory(suggestedCategoryPath.at(-1)?.id ?? '');
                handleCategorySelected(suggestedCategoryPath.at(-1)?.id ?? '');
                setCategoryPathPredicted([]);
                setReloadCategorySuggestionButtonVisible(false);
              }}>Aceptar</div>
            </div>
          </div>
        }
      </div>

      <div className='mb-4'>
        {createLabel('productDescription', 'Descripción')}
        {createInput(
          'productDescription',
          {
            ...register('productDescription', { required: true}),
            rows: 8
          },
          true,
        )}
        {errors.productDescription && (
          <p className='text-red-500 text-xs italic'>
            Ingrese una descripción para el producto
          </p>
        )}
      </div>

      {productCategory && (
        <div>
          <div className='mb-4 flex gap-2'>
            <div>
              {createLabel('price', 'Precio')}
              {createInput(
                'price',
                {
                  ...register('price', {
                    required: true,
                    valueAsNumber: true,
                    validate: {
                      checkExistingPrice,
                    },
                  })
                }

              )}
              {errors?.price && (
                <p className='text-red-500 text-xs italic'>
                  El precio debe ser mayor o igual a {minProductCategoryPrice}{' '}
                  {currCurrencyMinCategoryPrice}
                </p>
              )}
            </div>
            <div>
              {createLabel('currency', 'Moneda')}
              <>
                <select
                  className='border w-full max-w-xl rounded p-2'
                  id='currency'
                  {...register('currency', {
                    required: true,
                    value: 'UYU',
                  })}
                >
                  <option value=''>--</option>
                  {currencyOptions.map((curr) => {
                    return (
                      <option key={curr} value={curr}>
                        {curr}
                      </option>
                    );
                  })}
                </select>
                {productCategory && errors.currency && (
                  <p className='text-red-500 text-xs italic'>
                    Seleccione una moneda
                  </p>
                )}
              </>
            </div>
            <div>
              {createLabel('stock', 'Stock')}
              {createInput(
                'stock',
                register('stock', {
                  required: true,
                  validate: {
                    checkPositiveNumber,
                  },
                })
              )}
              {errors.stock && errors.stock.type === 'checkPositiveNumber' && (
                <p className='text-red-500 text-xs italic'>
                  El stock debe ser mayor que 0 (cero)
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { AddManualProductFormControls };


const loadMLCategory = async (categoryId: string): Promise<{ data: any; }> => {
  return await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/categories/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage?.session)?.sessionInfo?.token}`,
    }
  }).then((res) => res.json());
};

