import React, { useEffect } from 'react';
import { MLFormField } from 'components/MLForm/MLFormField';
import { ML_FORM_TYPES } from 'components/MLForm/types/MLForm.types';
import { Control } from 'react-hook-form';
import { memoize } from 'lodash';
import { useGetSelectedProductAttributesValuesManual } from 'hooks/useGetSelectedProductAttributesValues';
import axios from 'axios';

function processComponents(comps: []) {
  const processed = comps?.reduce((prev: any, curr: any) => {
      if (curr.component === ML_FORM_TYPES.LINKED_BY_CONNECTOR_INPUT) {
        console.log('>> curr: ', curr);
        const innerComponents = curr.attributes.map((attr: any) => ({
          "component": "NUMBER_UNIT_INPUT",
          "label": `${attr.name} (${attr.default_unit_id})`,
          "ui_config": {
            "allow_custom_value": false,
            "allow_filtering": false
          },
          "attributes": [
            {
              "id": attr.id,
              "name": attr.name,
              "value_type": "number_unit",
              "value_max_length": 255,
              "tags": attr.tags,
              "default_unit_id": attr.default_unit_id,
              "units": [{
                "id": attr.default_unit_id,
                "name": attr.default_unit_id
              }],
              "hierarchy": "FAMILY",
              "relevance": 1
            }
          ],
          "default_unified_unit_id": attr.default_unit_id,
          "unified_units": [{
            "id": attr.default_unit_id,
            "name": attr.default_unit_id
          }],
        }));

        return [...prev, ...innerComponents];
      } else if(curr.component === ML_FORM_TYPES.BOOLEAN_INPUT) {
        return [...prev, {
          ...curr,
          label: `${curr.attributes[0].name} (si/no)`,
        }];
      } else if(curr.component === ML_FORM_TYPES.COMBO && !curr.ui_config?.allow_custom_value) {
        return [...prev, {
          ...curr,
          label: `${curr.attributes[0].name} (${curr.attributes.map((attr: any) => attr.name).join('/')})`,
        }];
      } else {
        return [...prev, curr];
      }
    }, [])
    .filter(Boolean)
    .map((comp: any) => {
      if (comp.component === ML_FORM_TYPES.LINKED_BY_CONNECTOR_INPUT) {
        return {
          ...comp,
        };
      }

      const compAttrs = comp.attributes[0];
      const compTags = compAttrs.tags;
      // check where to add hidden components to send
      if (compTags.includes('hidden')) {
        return false;
      }
      return {
        ...comp,
        attributes: compAttrs,
        label: comp.label
      };
    })
    .filter(Boolean);
  return processed;
}

function processGroup(group: any) {
  const groupComponents = processComponents(group.components);
  if (!groupComponents.length) {
    return;
  }
  // console.log(">> group all: ", group);
  const groupObj = {
    id: group.id,
    label: group.label,
    components: groupComponents,
  };
  return groupObj;
}

function AddManualProductFormAttributes({
  categoryId,
  control,
  attrSections,
  setAttrSections,
  attributeValues = [],
  getValues,
  asin,
}: {
  categoryId: string | undefined;
  control: Control<any>;
  attrSections: any;
  setAttrSections: (attrs: any[]) => void;
  attributeValues?: { name: string; value: string | number }[];
  getValues: (name: string) => any;
  asin?: string;
}) {
  const {
    getSelectedProductAttributesValues,
    loading: loadingAttributes,
    error: errorLoadingAttributes,
    data: selectedProductAttributesValuesData,
  } = useGetSelectedProductAttributesValuesManual();
  useEffect(() => {
    const getAttrComps = async () => {
      if (!categoryId) {
        setAttrSections([]);
        return;
      }

      try {
        /**
         * list of categoriesId without input/control/component created in Atibo
         * 'MLU442548', // done
         * 'MLU434708', // done
         * 'MLU442424', // done
         * 'MLU456415', // done
         * 'MLU3724', // done
         * ['MLU1744',  // text_output --> Pending (confirm apartament/house)
         * 'MLU1473', // BOOLEAN_CHECKBOX --> Pending (confirm apartament/house)
         * 'MLU1467'] // HOUSE_NUMBER_INPUT --> Pending (confirm apartament/house)
         */
        const resp = await loadMLCategory(categoryId);

        
        if (Array.isArray(resp?.data?.groups) && resp?.data?.groups?.length) {
          const lastGroup = resp?.data?.groups?.at(-1) || {
            components: [],
          };

          const groups = [
            ...resp.data.groups.slice(0, 2),
            {
              ...lastGroup,
              components: lastGroup.components.filter((i: any) => i.attributes?.some((i: any) => i.tags?.includes("conditional_required") || i.tags?.includes("required") || i.id === "GTIN"))
            }
          ];

          const foundIds: string[] = [];
          const groupsWithoutDuplicates = groups.map((group: any) => {
            const componentsWithoutDuplicates = group.components.filter((comp: any) => {
              if (foundIds.includes(comp.attributes?.[0]?.id)) {
                return false;
              }
              foundIds.push(comp.attributes?.[0]?.id);
              return true;
            });
            return {
              ...group,
              components: componentsWithoutDuplicates,
            };
          })
          const processedGroups = groupsWithoutDuplicates.map(processGroup).filter(Boolean);
          setAttrSections(processedGroups);
        } else {
          //Empty attr section arr: No validation for attributes needed during form submission;
          // setAttrSections([]);
        }
      } catch (err) {
        console.error('>> Error - AddManualProductFormAttributes: ', err);
      }
    };
    getAttrComps();
  }, [categoryId]);

  const createUIComponent = (comp: any, attributeValues: { name: string; value: string | number }[]) => {
    // console.log('>>> --->>> comp:', comp);
    return (
      <MLFormField
        key={`${comp.label}-${comp.attributes.id}`}
        controlType={comp.component}
        label={
          Array.isArray(comp.attributes) ? comp.label : comp.attributes.name
        }
        attributes={comp.attributes}
        config={comp.ui_config}
        component={comp}
        opts={{ control }}
        value={
          attributeValues.find(
            (attr) => [comp?.label?.toLowerCase().split(' (')[0], comp?.attributes?.id?.toLowerCase().split(' (')[0]].includes(attr?.name?.toLowerCase())
          )?.value ?? ''
        }
      />
    );
  };

  const allComponents = attrSections?.reduce((prev: any, curr: any) => {
    return curr.components?.length ? [...prev, ...curr.components] : prev;
  }, []) || [];

  const filledAttributes = allComponents.filter(Boolean).map((comp: any) => {
    const attributes = comp.attributes;
    const attrName = attributes.name;
    const attrValue = [...(selectedProductAttributesValuesData?.getSelectedProductAttributesValues?.filter((attr: any) => attr?.value) || []), ...attributeValues]?.find((attr: any) => attr.name.split(' (')[0].trim() === attrName.split(' (')[0])?.value.trim() ?? 
      attributeValues.find((attr: any) => attr.name === attributes.id)?.value ?? '';
    return {
      name: comp.label.split(' (')[0].trim(),
      value: attrValue,
    };
  });
  

  const fillEmptyAttributes = () => {
    const selectedAttributes = allComponents.map((comp: any) => {
      const attr = comp.attributes;
      let attrName = attr.name;
      if (comp.default_unified_unit_id) {
        attrName += ` (${comp.default_unified_unit_id})`;
      } else {
        attrName += comp.attributes.value_type === 'boolean' ? ' (Sí/No)' : '';
      }
      
      if (comp.component === ML_FORM_TYPES.COMBO && !comp.ui_config?.allow_custom_value) {
        attrName += ` (${comp.attributes.values.map((val: any) => val.name).join('/')})`;
      }

      const attrValue = getValues(attrName);
      return {
        name: attrName,
        value: attrValue,
      };
    }).filter((attr: any) => !['Marca', 'Modelo', 'Fabricante', 'Código universal de producto'].includes(attr.name));
    console.log('>> fillEmptyAttributes: ', selectedAttributes);
    if(!asin || !selectedAttributes?.length) return;
    const selectedAttributeIds = selectedAttributes.filter((item: any) => !item.value?.length).map((attr: any) => attr.name).join(',');
    getSelectedProductAttributesValues({
      variables: {
        categoryId,
        sku: asin,
        selectedAttributeIds,
      },
    });
  }

  return !categoryId ||
    !Array.isArray(attrSections) ||
    attrSections.length === 0 ? (
    <></>
  ) : (
    <div className='w-full'>
      <div className='mb-4'>
        <div className='flex'>
          <div className='text-xl font-bold mb-4'>Datos del Producto</div>
          <div className={`text-white rounded-md px-2 py-1 ${loadingAttributes ? 'bg-primary-500' : 'bg-primary-700 cursor-pointer'} mx-2 h-8`} onClick={fillEmptyAttributes}>Llenar con IA</div>
          <div>{(errorLoadingAttributes && JSON.stringify(errorLoadingAttributes)) || ''}</div>
        </div>
        <div className='flex flex-wrap'>
          {loadingAttributes ? 'cargando...' : allComponents.map((comp: any) => createUIComponent(comp, filledAttributes))}
        </div>
      </div>
    </div>
  );
}

export { AddManualProductFormAttributes };

const loadMLCategory = memoize(async (categoryId: string) => {
  return await axios.get(
    // Keeping for testing
    // 'https://api.mercadolibre.com/categories/MLU6344/technical_specs/input'
    `https://api.mercadolibre.com/categories/${categoryId}/technical_specs/input`
  );
}, (categoryId: string) => categoryId);
