import { Accordion } from 'flowbite-react';
import { useAddSettingsManual } from 'hooks/useAddSettings';
import { useSearchSettingsManual } from 'hooks/useSearchSettings';
import { useEffect, useState } from 'react';
import MargenesEnvio from './accordionComponents/MargenesEnvio';
import CostosImpuestos from './accordionComponents/CostosImpuestos';
import PlantillasSubidas from './accordionComponents/PlantillasSubidas';

export default function SettingPage() {
  const { addSettings, loading } = useAddSettingsManual();
  const { searchSettings } = useSearchSettingsManual();

  const [editSetting, setEditSetting] = useState(false);

  const [ranges, setRanges] = useState([
    { min: 0, max: 500, percentage: 0 },
    { min: 500, max: Infinity, percentage: 0 },
  ]);

  const [rangesKg, setRangesKg] = useState([
    { min: 0, max: 500, kilogramos: 0, moneda: 'USD' },
    { min: 500, max: Infinity, kilogramos: 0, moneda: 'USD' },
  ]);

  // useEffect(() => {
  //   const getSettings = async () => {
  //     const data = await searchSettings();

  //     if (data.data.searchSettings.settings !== null) {
  //       setEditSetting(true);
  //       const { cuota, meli, dias, porcentaje_ganancia } =
  //         data.data.searchSettings.settings;
  //     }
  //   };

  //   getSettings();
  // }, []);

  useEffect(() => {
    const hasEmptyRange = ranges
      .slice(0, -1)
      .some((range, idx) => range.max !== ranges[idx + 1].min);
    // onValidatedChanged(!hasEmptyRange);
  }, [ranges]);

  return (
    <Accordion alwaysOpen>
      <Accordion.Panel>
        <Accordion.Title>Margenes y envios</Accordion.Title>
        <Accordion.Content>
          <MargenesEnvio
            ranges={ranges}
            rangesKg={rangesKg}
            setRanges={setRanges}
            setRangesKg={setRangesKg}
          />
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>
          Ajuste de costos, impuestos y lista negra
        </Accordion.Title>
        <Accordion.Content>
          <CostosImpuestos editSetting={editSetting} />
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>Plantilla y subidas de productos</Accordion.Title>
        <Accordion.Content>
          <PlantillasSubidas />
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}
