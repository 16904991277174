import React, { useEffect, useState } from 'react';
import { usePublishProductManual } from 'hooks/usePublishProductManual';
import AddManualProductForm from './AddManualProductForm';
import {
  AddCatalogProductResultError,
  AddCatalogProductResultLoading,
  AddCatalogProductResultSuccess,
} from '../common/AddCatalogProductResult';
import { PRODUCT_FORM_STATES } from '../types/AddCatalogProduct.types';

function AddManualProduct({
  visible,
  onCancel,
  onPublish,
  closeButtonLabel = 'Enviar link',
  asin = '',
  titleAsinProduct = '',
  productDescriptionAsin = '',
  productImages = [],
  productName = '',
  categoryPath = [],
  attributeValues = [],
  price = 0,
  currency = 'UYU',
}: {
  visible: boolean;
  onCancel: () => void;
  onPublish: (permalink: string) => void;
  closeButtonLabel?: string;
  asin?: string;
  titleAsinProduct: string;
  productDescriptionAsin: string;
  productImages: any;
  productName?: string;
  categoryPath?: string[];
  attributeValues?: { name: string, value: string | number }[];
  price?: number;
  currency?: string;
}) {
  const {
    publishProduct,
    loading: publishingLoading,
    error: publishingError,
    publishProductResponse,
  } = usePublishProductManual();
  const [selectedImages, setSelectedImages] = useState<any>(productImages);
  const [showCreationProductResult, setShowCreationProductResult] =
    useState(false);

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  let currentFormState: PRODUCT_FORM_STATES;
  if (publishingLoading) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_LOADING;
  } else if (publishingError) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_ERROR;
  } else if (showCreationProductResult) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_SUCCESS;
  } else {
    currentFormState = PRODUCT_FORM_STATES.SHOW_SUCCESS;
  }

  useEffect(() => {
    if (publishProductResponse) {
      setShowCreationProductResult(publishProductResponse.publishManualProduct.id);
    }
  }, [publishProductResponse]);


  return !visible ? (
    <></>
  ) : (
    <div className='sm:p-3'>
      <div
        className='fixed top-0 left-0 lg:w-screen lg:h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'
      >
        <div
          className='w-screen flex justify-center max-h-screen overflow-y-auto'
        >
          {
            <div className='max-w-screen-xl w-full'>
              <div className='bg-white shadow-md rounded px-8 pt-12 pb-8 mb-4'>
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_LOADING && <AddCatalogProductResultLoading />}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_ERROR && <AddCatalogProductResultError />}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_SUCCESS && <AddCatalogProductResultSuccess
                  handleCloseOkSuccess={() => {
                    onPublish(publishProductResponse.publishManualProduct.id);
                  }}
                  addCatalogProductInfo={String(showCreationProductResult)}
                  closeButtonLabel={closeButtonLabel}
                />}
                {currentFormState == PRODUCT_FORM_STATES.SHOW_SUCCESS  && <AddManualProductForm
                  asin={asin}
                  setShowCreationProductResult={setShowCreationProductResult}
                  publishProduct={publishProduct}
                  handleCancel={handleCancel}
                  productName={productName}
                  price={price ?? 0}
                  titleAsinProduct={titleAsinProduct?.replace(/[^a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚüÜ]/g, '') ?? ''}
                  productDescriptionAsin={productDescriptionAsin}
                  productImages={selectedImages}
                  onProductImagesChanged={(images: any) => { setSelectedImages(images) }}
                  categoryPath={categoryPath ?? []}
                  attributeValues={attributeValues}
                />}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export { AddManualProduct };
