const { useManualQuery } = require('graphql-hooks');

export const Query = `
query GetSelectedProductAttributesValues($categoryId: String!, $sku: String!, $selectedAttributeIds: String!) {
    getSelectedProductAttributesValues(categoryId: $categoryId, sku: $sku, selectedAttributeIds: $selectedAttributeIds) {
        name
        value
    }
}
`

export const useGetSelectedProductAttributesValuesManual = () => {
    const [getSelectedProductAttributesValues, { loading, error, data }] = useManualQuery(Query);

    return {
        getSelectedProductAttributesValues,
        loading,
        error,
        data,
    };
}