import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

export const SyncStoreProgressChart = (props: {progress: number, total: number}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [option, setOption] = useState({
    title: {
      text: 'Sincronización de productos',
      left: 'center',
      top: 20
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0',
      right: 'center'
    },
    series: [
      {
        name: 'Proceso de sincronización',
        type: 'pie',
        radius: ['70%', '40%'],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 0, name: `Completado`, itemStyle: { color: '#00ffa8' }},
          { value: 100, name: 'Pendiente', itemStyle: { color: '#f1f2f6' }}
        ]
      }
    ]
  });

  useEffect(() => {
    if (chartRef.current && !chartInstance.current) {
      // @ts-ignore
      chartInstance.current = echarts.init(chartRef.current);
    }
  }, []);

  useEffect(() => {
    const chart = chartInstance.current;
    if (!chart) return;
    
    // @ts-ignore
    chart?.setOption({
      ...option,
      series: [
        {
          ...option.series[0],
          data: [
            { value: props.progress, name: `Completado (${props.progress}/${props.total})`, itemStyle: { color: '#00ffa8' }},
            { value: props.total - props.progress, name: `Pendiente (${props.total - props.progress}/${props.total})`, itemStyle: { color: '#f1f2f6' }}
          ]
        }
      ]
    });
  
  }, [props.progress, props.total]);

  return <div><div ref={chartRef} style={{ width: 320, height: 340 }} /></div>;
}