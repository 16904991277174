import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchCancelledOrderManual } from 'hooks/useSearchCancelledOrders';
import AntDesignAmazonCircleFilled from './iconComponent/AmazonIconComponent';
import ArcticonsMercadoLibre from './iconComponent/MercadoLibreComponent';

const OrderDetail = ({ orderInfo }) => {
  const [precio, setPrecio] = useState();
  const [dateCreated, setDateCreated] = useState();
  const [checkStock, setCheckStock] = useState(false);
  const navigate = useNavigate();
  const { searchCancelledOrders } = useSearchCancelledOrderManual();

  useEffect(() => {
    if (orderInfo) {
      setCheckStock(false);
      setPrecio(orderInfo.currency_id + '' + orderInfo.unit_price);
      const d = new Date(orderInfo.dateCreated);
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const month = d.getMonth() + 1;
      const year = d.getFullYear();
      const date = `${day}/${month}/${year}`;
      setDateCreated(date);

      async function getCancelledOrder() {
        const data = await searchCancelledOrders({
          variables: {
            item: orderInfo.id,
          },
        });

        if (data.data.searchCancelledOrders.orders.length > 0) {
          setCheckStock(true);
        }
      }

      getCancelledOrder();
    }
  }, [orderInfo]);

  const handlerClickAjustes = () => {
    navigate('/etiquetas');
  };

  return (
    <div className='h-full'>
      <div className='w-full justify-end inline-flex' style={{ height: '10%' }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='w-6 h-6 cursor-pointer'
          onClick={handlerClickAjustes}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z'
          />
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
          />
        </svg>
      </div>
      <div
        className='self-stretch bg-white flex-col justify-start items-start flex'
        style={{ height: '70%' }}
      >
        <div className='w-full h-full bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'>
          <div className='inline-flex p-4 ext-sm font-normal font-["Inter"] leading-tight'>
            <div className='mx-4 text-gray-500'>
              Enlace ML:{' '}
              <span className='text-primary-700'>
                <a href={orderInfo?.item?.permalink} target='_blank'>
                  {orderInfo?.id}
                </a>
              </span>
            </div>
            <div className='mx-4 text-gray-500'>
              Enlace Proveedor:{' '}
              <span
                className={`${
                  checkStock ? 'text-orange-400' : 'text-primary-700'
                } inline-flex`}
                title={`${checkStock ? 'Verificar producto en stock' : ''}`}
              >
                <a
                  href={orderInfo?.item?.amazonUrl}
                  target='_blank'
                  className='mr-1'
                >
                  {checkStock && '!'} {orderInfo?.item?.seller_custom_field}
                </a>
                {checkStock && (
                  <>
                    <a href={`https://www.amazon.com/gp/your-account/order-history/?search=${orderInfo?.item?.seller_custom_field}`} target='_blank'><AntDesignAmazonCircleFilled /></a>
                    <a href={`https://www.mercadolibre.com.uy/ventas/omni/listado?search=${orderInfo?.item?.seller_custom_field}`} target='_blank'><ArcticonsMercadoLibre /></a>
                  </>
                )}
              </span>
            </div>
          </div>
          <div className='inline-flex pt-0 pl-4 pr-4 pb-4 ext-sm font-normal font-["Inter"] leading-tight'>
            <div className='mx-4 text-gray-500'>
              Precio: <span className='text-primary-700'>{precio}</span>
            </div>
            <div className='mx-4 text-gray-500'>
              Fecha: <span className='text-primary-700'>{dateCreated}</span>
            </div>
            <div className='mx-4 text-gray-500'>
              Pack:{' '}
              <span className='text-primary-700'>{orderInfo?.order_id}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
