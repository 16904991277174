import React, { useEffect, useState } from 'react';
import ModalUploadedFile from './ModalUploadedFile';

const UploadFileIcon = ({ orderInfo, messagesBuyer, setMessagesBuyer }) => {
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [base64File, setBase64File] = useState('');
  const [fileInfo, setFileInfo] = useState({});

  useEffect(() => {
    if (images.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(newImageURLs);
    setOpenModal(true);
  }, [images]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onImageChange = async (e) => {
    // console.info('images info', e.target.files);
    const base64 = await convertBase64(e.target.files[0]);
    const { name, type, size } = e.target.files[0];
    
    setFileInfo({ name, type, size });
    setBase64File(base64);
    setImages([...e.target.files]);
  };
  return (
    <>
      <ModalUploadedFile
        openModal={openModal}
        imageURLs={imageURLs}
        setImageURLs={setImageURLs}
        setOpenModal={setOpenModal}
        textMessage={textMessage}
        setTextMessage={setTextMessage}
        base64File={base64File}
        orderInfo={orderInfo}
        messagesBuyer={messagesBuyer}
        setMessagesBuyer={setMessagesBuyer}
        fileInfo={fileInfo}
      />
      <div>
        <label htmlFor='fileInput'>
          <span className='text-gray-500 hover:text-gray-700 cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6 mt-0.5'
            >
              <title>Adjuntar archivos</title>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 4.5v15m7.5-7.5h-15'
              />
            </svg>
          </span>
        </label>
      </div>
      <div>
        <input
          className='hidden'
          id='fileInput'
          type='file'
          multiple
          accept='*/*'
          onChange={onImageChange}
        />
      </div>
    </>
  );
};

export default UploadFileIcon;
