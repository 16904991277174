import React, { Fragment, useState, useEffect } from 'react';
import AlertMessage from 'components/utils/AlertMessage';
import AnswerLabel from './labels/AnswerLabel';
import { DANGERMSGALERT } from 'components/utils/constants';
import { useAnswerQuestionManual } from 'hooks/useAnswerQuestion';
import { useGetAnswerSuggestionsManual } from 'hooks/useGetAnswerSuggestions';
import { useSearchProductAlternativesManual } from 'hooks/useSearchProductAlternatives';
import { AnswerLoading } from 'components/utils';
import AddProductOnDemand from './addProductOnDemand/AddProductOnDemand';
import { AddCatalogProduct } from 'components/addProduct/catalog/AddCatalogProduct';
import { SelectCatalogOrCopilot } from 'components/addProduct/manual/SelectCatalogOrCopilot';
import { ProviderOptions } from './addProductOnDemand/Provider';

function AnswerSection({
  item,
  userEnteredAnswers,
  loadingSearchLabels,
  errorSearchLabels,
  labelError,
  labels,
  handlerLabelAnswer,
  handlerDeleteQuestion,
  setAsnwerQuestionId,
  handlerShowWindow,
  setUserEnteredAnswers,
  loadedMessages,
  setLoadedMessages,
  showAnswerQuestionButton
}) {
  const { getAnswerSuggestions } = useGetAnswerSuggestionsManual();
  const { searchProductAlternatives, data: productAlternativesData, loading: loadingProductAlternatives } = useSearchProductAlternativesManual();
  const [selectedProductAlternative, setSelectedProductAlternative] = useState([]);
  const [showAddCatalogProductDialog, setShowAddCatalogProductDialog] = useState(false);
  const [showManualProductDialog, setShowManualProductDialog] = useState(false);
  const [catalogProductToAdd, setCatalogProductToAdd] = useState(null);
  const [productOptions, setProductOptions] = useState({
    products: [],
    searchText: '',
  });
  const [showCopilot, setShowCopilot] = useState(false); // TODO: remove this line and the next one
  const [price, setPrice] = useState(0);
  const [gtin, setGtin] = useState(null);

  useEffect(() => {
    setSelectedProductAlternative(null);
    setProductOptions(productAlternativesData?.searchProductAlternatives);
  }, [productAlternativesData]);


  useEffect(() => {
    if (selectedProductAlternative?.id && selectedProductAlternative?.id.startsWith('MLU')) {

      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [item.id]: 'https://articulo.mercadolibre.com.uy/MLU-' + selectedProductAlternative?.id.replace('MLU', ''),
      });
    } else if (selectedProductAlternative?.catalogProductId && selectedProductAlternative.provider === ProviderOptions.MERCADOLIBRE) {
      setCatalogProductToAdd({ id: selectedProductAlternative.catalogProductId, name: selectedProductAlternative.name, domainId: selectedProductAlternative.domainId });
      setShowAddCatalogProductDialog(true);
    } else if (selectedProductAlternative?.id && !showManualProductDialog) {
      setShowCopilot(true);
    } else {
      setSelectedProductAlternative(null);
    }
  }, [selectedProductAlternative]);

  const {
    answerQuestion,
    loading: loadingAnswerQuestion,
  } = useAnswerQuestionManual();

  const handleAnswerChange = async (questionId, value) => {
    if (value[value.length - 1] === '@') {
      if (value[value.length - 2] === ' ' || value.length === 1) {
        const newValue = value.slice(0, -1);
        setAsnwerQuestionId(questionId);
        handlerShowWindow(true);
        value = newValue;
      }
    }

    if (value === '**') {
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: 'cargando...',
      });
      const selectedQuestion = loadedMessages.find(
        (question) => question.id === questionId
      );
      const { data } = await getAnswerSuggestions({
        variables: { query: selectedQuestion.text, sku: selectedQuestion.skuItem, type: 'answer' },
      });
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: data.getAnswerSuggestions,
      });
    } else if (value === '++') {
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: 'cargando...',
      });
      const selectedQuestion = loadedMessages.find(
        (question) => question.id === questionId
      );
      await searchProductAlternatives({
        variables: { productTitle: item.itemName, question: selectedQuestion.text },
      });
    } else {
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: value,
      });
    }
  };

  const handleAnswerQuestion = async (questionId) => {
    console.log('Respondiendo la pregunta', questionId);

    const result = await answerQuestion({
      variables: {
        questionId: Number(questionId),
        answer: userEnteredAnswers[questionId],
      },
    });

    if (result.data.answerQuestion.status == 'ok') {
      // remove answer text from userEnteredAnswers
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: '',
      });
      // remove question from loadedMessages
      setLoadedMessages(
        loadedMessages.filter((question) => question.id !== questionId)
      );
    }
  };


  const sendAnswerEnabled = userEnteredAnswers[item.id]?.length;
  return (
    <Fragment>
      {loadingAnswerQuestion && <AnswerLoading />}
      {showAddCatalogProductDialog && <AddCatalogProduct
        product={catalogProductToAdd}
        visible={true}
        onPublish={(newId) => {
          console.log('onPublish', newId);
          setUserEnteredAnswers({
            ...userEnteredAnswers,
            [item.id]: 'Hola. LE ADJUNTO LINK: https://articulo.mercadolibre.com.uy/MLU-' + newId.replace('MLU', '') + '. Saluda atentamente, Juan por Kasver UY', // TODO: make this configurable
          });
          setShowAddCatalogProductDialog(false);
        }}
        closeButtonLabel='Enviar link'
        asinPrice={price}
        gtin={gtin}
        onCancel={() => setShowAddCatalogProductDialog(false)} />}
      { showCopilot && !showManualProductDialog && <SelectCatalogOrCopilot
        asin={selectedProductAlternative?.id}
        onCancel={() => {
          setShowCopilot(false);
          setSelectedProductAlternative(null);
        }}
        onPublish={(newId) => {
          console.log('onPublish', newId);
          const mercadoLibreLink = `https://articulo.mercadolibre.com.uy/MLU-${newId.replace('MLU', '')}`;
          const amazonLink = `https://www.amazon.com/dp/${newId}`;
          const answerText = `Hola. LE ADJUNTO LINK: ${newId.startsWith('MLU') ? mercadoLibreLink : amazonLink}. Saluda atentamente, Juan por Kasver UY`; // TODO: make this configurable
          setUserEnteredAnswers({
            ...userEnteredAnswers,
            [item.id]: answerText
          });
          setShowCopilot(false);
        }}
      />}
      {!selectedProductAlternative && productOptions &&
        productOptions?.products?.length > 0 && <AddProductOnDemand
          list={productOptions.products}
          search={productOptions.searchText}
          loading={loadingProductAlternatives}
          onSelected={(item) => setSelectedProductAlternative(item)}
          onCancel={() => {
            setSelectedProductAlternative(null);
            setProductOptions(null);
          }}
          onSearch={(text) => searchProductAlternatives({ variables: { productTitle: text, question: "tienen este producto?" } })}
        />
      }
      <div className={`pt-4 pl-4 ${loadingAnswerQuestion && 'hidden'}`}>
        <div className='rounded overflow-hidden shadow-lg'>
          <div className='flex'>
            <div className='w-1/4'>
              <a href={item?.permalink} target='_blank' rel='noreferrer'>
                <img
                  className='w-24 h-24'
                  src={item?.itemThumbnail}
                  alt='img'
                />
              </a>
            </div>
            <div className='w-3/4'>
              <div className='mr-6 text-sm'>{item.itemName}</div>
              <div className='justify-between mr-14 mt-6 grid grid-cols-3 gap-4'>
                <div className='flex'>
                  <span
                    className={`p-1 rounded-md ${item.statusItem == 'active'
                      ? 'bg-green-200 text-green-700'
                      : item.statusItem == 'paused'
                        ? 'bg-orange-200 text-orange-700'
                        : item.statusItem == 'closed' &&
                        'bg-red-200 text-red-700'
                      } text-xs h-7 font-bold`}
                  >
                    {item.statusItem[0].toUpperCase() +
                      item.statusItem.slice(1)}
                  </span>
                </div>
                <div className='flex p-1 text-green-700'>
                  <span className='text-sm'>{item.itemCurrency}</span>
                  <span className='text-sm ml-1'>${new Intl.NumberFormat('es-UY').format(item.itemPrice)}</span>
                </div>
                <div className='flex p-1 text-primary-700'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418'
                    />
                  </svg>

                  <span className='text-sm ml-1'>
                    <a
                      href={`https://amazon.com/dp/${item.skuItem}`}
                      target='_blank'
                    >
                      {item.skuItem}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='px-6 py-4'>
            {loadingSearchLabels && item.status === 'UNANSWERED' && (
              <span>cargando...</span>
            )}
            {errorSearchLabels && item.status === 'UNANSWERED' && (
              <AlertMessage
                message={labelError}
                title='Error: '
                type={DANGERMSGALERT}
              />
            )}
            {labels.length > 0 && item.status === 'UNANSWERED' && (
              <div key={item.id}>
                <AnswerLabel
                  labels={labels}
                  handlerLabelAnswer={handlerLabelAnswer}
                  elementId={item.id}
                />
              </div>
            )}
            <div className='h-8 w-full p-0.5 rounded-t-lg border-2 border-primary-100 bg-primary-100 text-primary-700 font-bold flex text-sm '>
              <span className='ml-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                  />
                </svg>
              </span>
              <span className='ml-1'>{item.clientName}</span>
            </div>
            <div className='mb-1 h-24 w-full p-0.5 rounded-b-lg border-2 border-primary-100 border-t-white overflow-y-auto text-sm'>
              {item.text}
            </div>
            {(item.status === 'UNANSWERED' && (
              <textarea
                name='textareaAnswer'
                id='textareaAnswer'
                cols='30'
                rows='5'
                className={`p-2 border rounded-md border-gray-500 w-full`}
                value={userEnteredAnswers[item.id]}
                onChange={(event) =>
                  handleAnswerChange(item.id, event.target.value)
                }
              ></textarea>
            )) || (
                <div className='h-auto w-full p-0.5 rounded-t-lg border-2 border-primary-100 text-primary-200 font-bold flex text-sm '>
                  <span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5'
                      />
                    </svg>
                  </span>
                  <span className='ml-2'>{item.answer}</span>
                </div>
              )}
          </div>
          {item.status === 'UNANSWERED' && showAnswerQuestionButton && (
            <div className='px-6 pt-4 pb-2 justify-between flex'>
              <button
                className={`bg-red-200 border border-red-200 hover:border-red-500 inline-flex items-center px-4 py-2 ml-1 font-semibold leading-6 text-sm shadow rounded-md text-red-700 transition ease-in-out duration-150`}
                onClick={() => handlerDeleteQuestion(item.id)}
              >
                Eliminar pregunta
              </button>
              <button
                className={`${sendAnswerEnabled && item.statusItem == 'active'
                  ? 'bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700'
                  : 'bg-gray-200 border border-gray-200 hover:border-gray-500 text-gray-700'
                  } inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150 `}
                onClick={() =>
                  sendAnswerEnabled &&
                  item.statusItem == 'active' &&
                  handleAnswerQuestion(item.id)
                }
              >
                Responder
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default AnswerSection;
