import React from "react";

export const ActionButton = ({ onClick, message }: {onClick : () => void; message: string}) => {
  onClick = onClick || (() => {});
  message = message || "Publicar";
  return <>
    <div className='w-[17%] flex justify-center'>
      <div
        className={`pl-4 pr-1 py-0.5 bg-purple-50 rounded-2xl justify-start items-center gap-1 flex cursor-pointer`}
        onClick={onClick}
      >
        <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
          {message}
        </div>
        <div className='w-3 h-3 relative'></div>
      </div>
    </div>
  </>
}
