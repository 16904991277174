import { useState } from 'react';

const QuestionFilterTab = ({ title, selectedTab, notificationsCount, onClick }) => {
    return <div className={`m-2 cursor-pointer relative mr-7 ${selectedTab === title ? 'text-primary-700' : 'text-gray-500'}`} onClick={() => onClick(title)}>
        {notificationsCount && notificationsCount > 0 ? (
            <span className='bg-red absolute -right-7 -top-3 bg-red-500 text-white text-xs rounded-full py-1 px-2'>{notificationsCount}</span>
        ) : null}
        <span>{title}</span>
    </div>;
};

const tabNames = ['Sin responder', 'Respondidas', 'Historial'];

export const QuestionsFilterTabs = ({ onTabSelected, newQuestions }) => {
    const [activeFilter, setActiveFilter] = useState(tabNames[0]);

    const onTabClicked = (tab) => {
        setActiveFilter(tab);
        onTabSelected(tabNames.indexOf(tab));
    };

    return <div className="flex flex-inline font-semibold text-sm text-gray-500 mb-4">
        <QuestionFilterTab title="Sin responder" notificationsCount={newQuestions} selectedTab={activeFilter} onClick={onTabClicked} />
        <QuestionFilterTab title="Respondidas" selectedTab={activeFilter} onClick={onTabClicked} />
        <QuestionFilterTab title="Historial" selectedTab={activeFilter} onClick={onTabClicked} />
    </div>;
};
