import { useQuery, useManualQuery } from 'graphql-hooks';

const SearchElegibleProductsQuery = `
  query SearchElegibleProducts($query: String!, $offset: Int, $limit: Int) {
    searchElegibleProducts (query: $query, offset: $offset, limit: $limit) {
        offset
        total
        products {
            id
            details {
                title
                site_id
                price
                currency_id
                available_quantity
                sold_quantity,
                thumbnail,
                permalink,
                pictures {
                    url
                }
                catalog_product_id
            }
            elegibilityDetails {
                buyBoxElegible
                reason
                status
            }
        }
    }
  }
`

const useSearchElegibleProducts = (query) => {
    const { loading, error, data } = useQuery(SearchElegibleProductsQuery, {
        variables: {
            query
        }
    });
    return { loading, error, data }; 
}


const useSearchElegibleProductsManual = () => {
    const [searchElegibleProducts, { loading, error, data }] = useManualQuery(SearchElegibleProductsQuery);
    const { products, offset, total } = data ? data.searchElegibleProducts : { products: [], offset: 0, total: 0 };
    return { searchElegibleProducts, loading, error, products, offset, total }; 
}

export { useSearchElegibleProducts, useSearchElegibleProductsManual };