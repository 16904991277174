const PlantillasSubidas = () => {
  return (
    <>
      <div className='w-full flex'>
        <div className='w-[50%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <span>
              <h2>Descripcion al final de cada aplicacion comun</h2>
            </span>
          </div>
          <div className='mb-2'>
            <span>
              <h2>Cabecera</h2>
            </span>
          </div>
          <div className='mb-4 flex w-full'>
            <textarea className='rounded border-gray-300' rows={2} cols={55} />
          </div>
          <div className='mb-2'>
            <span>
              <h2>Pie de pagina</h2>
            </span>
          </div>
          <div className='mb-4 flex w-full'>
            <textarea className='rounded border-gray-300' rows={6} cols={55} />
          </div>
        </div>
        <div className='w-[50%] ml-4'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoAmazon'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Configurar enlaces
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-full`}>
                    <select
                      id='meli'
                      name='meli'
                      className='w-full h-[2.7rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    >
                      <option value='cadc'>
                        Voy a subir Links Amazon Catalogo
                      </option>
                      <option value='cadv'>Voy a subir ASINS</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full px-4 pt-0'>
                <textarea
                  className='rounded border-gray-300'
                  rows={9}
                  cols={55}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <button
          className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150`}
          type='submit'
        >
          Guardar
        </button>
      </div>
    </>
  );
};

export default PlantillasSubidas;
