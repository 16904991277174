import { SyncStoreProgressChart } from 'components/charts/SyncStoreProgressChart/SyncStoreProgressChart';
import React, { useEffect, useState } from 'react';
import { usersApi } from 'store/api/usersApi';
import { useGetStoreSyncProgressSubscription } from 'hooks/useGetStoreSyncProgressSubscription';

const DashboardCommon = ({ userInfoData }: any) => {
  const [createMLRedirectUrl] = usersApi.useCreateMLRedirectUrlMutation();
  
  const authorizeMercadoLibre = async () => {
    const { url } = await createMLRedirectUrl(userInfoData).unwrap();
    window.location.href = url;
  }

  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Por aquí verás información importante más adelante</b>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Para comenzar...</b>
        <div>
          ...vincula tu cuenta de MercadoLibre con Atibo
          <button
            type='button'
            className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 mt-5'
            onClick={authorizeMercadoLibre}
          >
            Vincular tienda de ML
          </button>
        </div>
      </div>
    </div>
  );
};

const DashboardSeller = ({ userInfoData }: any) => {
  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Ingresaste como vendedor de la tienda de:</b>
        <div className='pl-4'>{userInfoData.lastActiveStore.emailAdmin}</div>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>En esta tienda tus permisos son:</b>
        <ul>
          {userInfoData.lastActiveStore.permissions &&
            userInfoData.lastActiveStore.permissions.map(
              (perm: string, idx: number) => {
                return (
                  <li className='pl-4' key={`li-p-${idx}`}>
                    {perm}
                  </li>
                );
              }
            )}
        </ul>
      </div>
    </div>
  );
};

const DashboardAdmin = ({ userInfoData }: any) => {
  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Ingresaste como administrador de tu tienda:</b>
        <div className='pl-4'>ID: {userInfoData.storeId}</div>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>En esta tienda tus vendedores son:</b>
        <ul>
          {userInfoData.usersInStore &&
            userInfoData.usersInStore.map((seller: any, idx: number) => {
              return (
                <li className='pl-4' key={`li-p-${idx}`}>
                  {seller.email}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const OldHomePage = ({ userInfoData }: any) => {


  return (
    <div className='ml-60'>
      <div className='font-sans mt-8 relative'>
        <div className='font-semibold text-3xl mb-12'>Home</div>
      </div>

      <div className='m-3'>
        <div className='font-semibold text-xl mt-8 mb-3'>
          Bienvenido/a <strong>{userInfoData.firstname}</strong>!
        </div>

        {userInfoData.lastActiveStore ? (
          <DashboardSeller userInfoData={userInfoData} />
        ) : userInfoData.isAdmin ? (
          <DashboardAdmin userInfoData={userInfoData} />
        ) : (
          <DashboardCommon userInfoData={userInfoData} />
        )}

      </div>
    </div>
  );
};

const HomePage = () => {
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);

  useGetStoreSyncProgressSubscription((data) => {
    setProgress(data.progress);
    setTotal(data.total);
  });

  return <div>

    <SyncStoreProgressChart progress={progress} total={total} />
  </div>;
}

export default HomePage;

