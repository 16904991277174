import React, { Fragment, useEffect, useState } from 'react';
import Tbody from './Tbody';
import { useNavigate } from 'react-router-dom';

function Table({ data, handlerAddLabel, handlerDelete, handlerEdit }) {
  const [newData, setNewData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const handlerSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setNewData(
      data.filter(
        (d) =>
          d.title.toLowerCase().includes(query) ||
          d.description.toLowerCase().includes(query)
      )
    );
  }
  const handlerBackButton = () => {
    // navigate('/mensajes');
    navigate(-1);
  }
  return (
    <Fragment>
      <div className="container mx-auto relative overflow-x-auto shadow-md p-8">
        <div className="mb-5 inline-flex w-full justify-between">
          <input
            type="text"
            placeholder="Buscar..."
            className="border border-gray-500 p-2 w-80"
            onChange={handlerSearch}
          />
          <div>
          <button
            className="bg-red-200 border border-red-200 hover:border-red-500 inline-flex items-center px-4 py-2 ml-1 mr-2.5 font-semibold leading-6 text-sm shadow rounded-md text-red-700 transition ease-in-out duration-150"
            onClick={() => {
              handlerBackButton();
            }}
          >
            Volver
          </button>
          <button
            className="bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150"
            onClick={() => {
              handlerAddLabel();
            }}
          >
            Agregar
          </button>
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-500 uppercase bg-gray-100">
            <tr>
              <th scope="col" className="px-6 py-3">
                Titulo
              </th>
              <th scope="col" className="px-6 py-3">
                Valor
              </th>
              <th scope="col" className="px-6 py-3">
                Modulo
              </th>
              <th scope="col" className="px-6 py-3">
                Accion
              </th>
            </tr>
          </thead>
          <Tbody
            data={newData}
            handlerDelete={handlerDelete}
            handlerEdit={handlerEdit}
          />
        </table>
      </div>
    </Fragment>
  );
}

export default Table;
