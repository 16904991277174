import { useQuery, useManualQuery } from 'graphql-hooks';

const searchSettingsQuery = `
query searchSettings {
  searchSettings {
    settings {
      id
      cuota
      meli
      dias
      deleted
      porcentaje_ganancia
    }
  }
}
`;

const useSearchSettingsManual = () => {
  const [searchSettings, { loading, error, data }] = useManualQuery(searchSettingsQuery);
  const { settings } = data ? data.searchSettings : { settings: [] };
  return { searchSettings, loading, error, settings }; 
}

export {
  useSearchSettingsManual,
  searchSettingsQuery
};