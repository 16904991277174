import AddRowIcon from "./iconsComponents/AddRowIcon";

export const AddRowButtonComponent = ({ position, ranges, onAddRow, title }) => {
  return (
      <div className="mb-4"
        onClick={() =>
          onAddRow(+ranges[position].max, +ranges[position + 1].min, position)
        }
      >
        <AddRowIcon title={title} />
      </div>
  );
};
