import React from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const API_BASE_URL = 'http://localhost';

const MessageBox = ({ messagesBuyer, orderInfo }) => {
  // console.info('messagesBuyer..', messagesBuyer);

  const handlerFileDownload = (fileName, type) => {
    const token = localStorage.getItem('session')
      ? JSON.parse(localStorage.getItem('session')).sessionInfo?.token
      : '';
    // console.info('token...', token);

    const url = `${API_BASE_URL}/downloaded-files?fileName=${fileName}`;
    axios(`${url}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const file = new Blob([response.data], { type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ChatDateComponent = ({message, buyerSide}) => {

      const d = new Date(message.message_date.created);
      const day = d.getDate();
      const month = d.getMonth();
      const year = d.getFullYear();
      const hours = d.getHours();
      const minutes = d.getMinutes();

    return (
      <span className={buyerSide && 'text-gray-500 text-sm'}>
        {`${day}/${month + 1}/${year} ${hours}:${minutes}`}
      </span>
    );
  };

  return (
    <>
      {messagesBuyer &&
        messagesBuyer.map((message) => {
          return (
            <>
              <div
                key={Math.random() * 1000}
                className={`flex p-1 ${
                  message.from.user_id == orderInfo.seller_id && 'justify-end'
                }`}
              >
                <div className='min-w-0 max-w-[50%]'>
                  <div
                    className={`w-full justify-between p-4 rounded-lg ${
                      message.from.user_id == orderInfo.seller_id
                        ? 'text-white bg-primary-700'
                        : 'text-black bg-gray-100'
                    }`}
                  >
                    <div>
                      <div>
                        <span>{message.text}</span>
                      </div>
                      <div className='flex justify-end text-xs'>
                        <ChatDateComponent message={message} buyerSide={false}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                key={Math.random() * 2000}
                className={`block p-1 ${
                  message.from.user_id == orderInfo.seller_id && 'justify-end'
                }`}
              >
                {message &&
                  message.message_attachments !== null &&
                  message.message_attachments.map((fileInfo) => (
                    <div className='w-[50%]' key={Math.random() * 100}>
                      <div
                        key={fileInfo.filename}
                        className={`w-full justify-between p-0 rounded-lg`}
                        onClick={() =>
                          handlerFileDownload(fileInfo.filename, fileInfo.type)
                        }
                      >
                        <div>
                          <div className='h-16 p-1.5 flex border-2 border-gray-50 shadow-md rounded-md mt-2 hover:bg-gray-50 cursor-pointer'>
                            <div className='w-[15%]'>
                              <span className='h-full bg-primary-100 rounded-full shadow-md shadow-primary-200 flex justify-center p-3'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth='1.5'
                                  stroke='currentColor'
                                  className='w-6 h-6 text-primary-700'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z'
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className='w-[85%] grid ml-4'>
                              <span className='text-gray-500 text-lg truncate'>
                                {fileInfo.original_filename}
                              </span>
                              <div className='flex justify-between'>
                                <span className='text-gray-500 text-sm'>
                                  {fileInfo.convertedSize}
                                </span>
                                <span className='text-gray-500 text-sm'>
                                    <ChatDateComponent message={message} buyerSide={true}/>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          );
        })}
    </>
  );
};

export default MessageBox;
