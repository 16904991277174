import React from 'react';
import { AddManualProductCopilot } from './AddManualProductCopilot';
import { AddCatalogProduct } from '../catalog/AddCatalogProduct';
import { useGetProductByAsin } from 'hooks/useGetProductByAsin';
import { ProgressDialog } from './ProgressDialog';

export const SelectCatalogOrCopilot = ({
    asin,
    onCancel,
    onPublish,
}: {
    asin: string;
    onCancel: () => void;
    onPublish: (permalink: string) => void;
}) => {
    const { loading, data } = useGetProductByAsin(asin);

    if (loading) return <ProgressDialog
        title="Comprobando si el producto existe en el catálogo"
        progress={0}
        onCancel={onCancel} />;

    return data?.getProductByAsin?.id ? <AddCatalogProduct
        product={data.getProductByAsin}
        visible={true}
        onCancel={onCancel}
        onPublish={onPublish}
        asinPrice={data.getProductByAsin.price}
        gtin={data.getProductByAsin.gtin}
        asin={asin}
        closeButtonLabel="Enviar link" /> : <AddManualProductCopilot
        asin={asin}
        onCancel={onCancel}
        onPublish={onPublish} />;
};