import { useState } from 'react';
import { RangeSelector } from '../RangeSelector';
import { AddRowButtonComponent } from '../AddRowButtonComponent';
import { RangeSelectorCostoEnvio } from '../RangeSelectorCostoEnvio';

const MargenesEnvio = ({ ranges, rangesKg, setRangesKg, setRanges }) => {
  const [addRowIconTitle, setAddRowIconTitle] = useState('Agregar fila');
  const onAddRow = (min, max, position) => {
    const newRanges = [...ranges];
    newRanges.splice(position + 1, 0, { min, max, percentage: 0 });
    setRanges(newRanges);
  };

  const onRemoveRow = (position) => {
    const newRanges = [...ranges];
    newRanges.splice(position, 1);
    setRanges(newRanges);
  };

  const onMinChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      min: newValue,
    };
    setRanges(newRanges);
  };

  const onMaxChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      max: newValue,
    };
    setRanges(newRanges);
  };

  const onPercentageChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      percentage: +newValue,
    };
    setRanges(newRanges);
  };

  // Funciones de costo de envio
  const onAddRowCostoEnvio = (min, max, position) => {
    const newRanges = [...rangesKg];
    newRanges.splice(position + 1, 0, {
      min,
      max,
      kilogramos: 0,
      moneda: 'USD',
    });
    setRangesKg(newRanges);
  };

  const onRemoveRowCostoEnvio = (position) => {
    const newRanges = [...rangesKg];
    newRanges.splice(position, 1);
    setRangesKg(newRanges);
  };

  const onMinChangeCostoEnvio = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      min: newValue,
    };
    setRangesKg(newRanges);
  };

  const onMaxChangeCostoEnvio = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      max: newValue,
    };
    setRangesKg(newRanges);
  };
  return (
    <>
      <div className='w-full flex max-h-[380px]'>
        <div className='w-[50%] border-2 border-r-primary-100 border-x-white border-y-white overflow-auto'>
          <div className='mb-2'>
            <span>
              <h2>% ganancia sobre el valor del producto en Amazon</h2>
            </span>
          </div>
          {ranges.map((range, idx) => (
            <>
              <RangeSelector
                min={+range.min}
                max={+range.max}
                percentage={range.percentage}
                idx={idx}
                ranges={ranges}
                onMinChange={(newValue) => onMinChange(idx, newValue)}
                onMaxChange={(newValue) => onMaxChange(idx, newValue)}
                onPercentageChange={(newValue) =>
                  onPercentageChange(idx, newValue)
                }
                onRemoveRow={onRemoveRow}
              />
              {idx < ranges.length - 1 &&
                +range.max !== +ranges[idx + 1].min && (
                  <AddRowButtonComponent
                    position={idx}
                    ranges={ranges}
                    onAddRow={onAddRow}
                    title={addRowIconTitle}
                  />
                )}
            </>
          ))}
        </div>
        <div className='w-[50%] ml-4 overflow-auto'>
          <div className='mb-2'>
            <span>
              <h2>Costo del envio por Kg del envio del courrier</h2>
            </span>
          </div>
          {rangesKg.map((rangeKg, idx) => (
            <>
              <RangeSelectorCostoEnvio
                min={+rangeKg.min}
                max={+rangeKg.max}
                kilogramos={rangeKg.kilogramos}
                moneda={rangeKg.moneda}
                idx={idx}
                ranges={rangesKg}
                onMinChange={(newValue) => onMinChangeCostoEnvio(idx, newValue)}
                onMaxChange={(newValue) => onMaxChangeCostoEnvio(idx, newValue)}
                onRemoveRow={onRemoveRowCostoEnvio}
              />
              {idx < rangesKg.length - 1 &&
                +rangeKg.max !== +rangesKg[idx + 1].min && (
                  <AddRowButtonComponent
                    position={idx}
                    ranges={rangesKg}
                    onAddRow={onAddRowCostoEnvio}
                    title={addRowIconTitle}
                  />
                )}
            </>
          ))}
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <button
          className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150`}
          type='submit'
        >
          Guardar
        </button>
      </div>
    </>
  );
};

export default MargenesEnvio;
